// need remove
.search-box {
   position: relative;
   height: 40px;
   margin: 0 32px;
   border: 1px solid $color--gray-border;
   box-sizing: border-box;
   border-radius: 6px;
   display: flex;
   align-items: center;
   margin-bottom: 24px;
   .search-icon {
      display: grid;
      position: absolute;
      left: 12px;
      color: $color--black;
   }
   .filter-icon {
      display: grid;
      position: absolute;
      right: 12px;
      color: $color--text1;
      cursor: pointer;
   }
   input {
      padding: 0px 40px;
      @include font14_20;
      height: 100%;
      width: 100%;
   }
}

// new
.search-filter {
   position: relative;
   min-height: 40px;
   margin: 0 32px;
   border: 1px solid $color--gray-border;
   box-sizing: border-box;
   border-radius: 6px;
   display: flex;
   flex: 1;
   margin-bottom: 16px;
   cursor: text;
   padding: 4px 48px;
   gap: 4px 4px;
   font-family: $font--regular;
   flex-wrap: wrap;
   max-width: 100%;
   overflow: hidden;

   &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 4px;
      top: 0;
      height: 40px;
      width: 40px;
      color: $color--black;
      pointer-events: none;
   }
   &__clear-all {
      position: absolute;
      right: 4px;
      top: 0;
      height: 40px;
      width: 40px;
      cursor: pointer;
      color: $color--text3;
      transition: $transition-common;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      padding: 0;
      background: transparent;
      &:hover {
         color: $color--text1;
      }
   }

   &__input {
      padding: 0px;
      @include font14_20;
      height: 32px;
      flex: 1;
      background-color: transparent;
      border: none;
      outline: none;
      &::placeholder {
         color: $color--text4;
         opacity: 1;
      }
   }
   &__item {
      width: max-content;
      background-color: $color--black-10;
      border-radius: 4px;
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 2px 12px;
      height: 32px;
      @include font14_20;
      color: $color--text1;
      letter-spacing: -0.005em;
      gap: 4px;
      &:last-of-type {
         margin-right: 4px;
      }
      .sub-text {
         color: $color--text3;
         letter-spacing: -0.005em;
      }
      .value {
         overflow: hidden;
         text-overflow: ellipsis;
         white-space: nowrap;
         max-width: 290px;
      }
      i {
         display: grid;
         padding: 6px;
         cursor: pointer;
         color: $color--text4;
         transition: $transition-common;
         margin-right: -6px;
         &:hover {
            color: $color--text1;
         }
      }
   }
}

.ant-dropdown.search-filter-dropdown {
   min-width: 240px;
   font-family: $font--regular;
   .search-filter-menu {
      @include font14_20;
      min-width: 240px;
      max-width: calc(100vw - 48px);
      width: max-content;
      position: relative;
      border: none;
      box-shadow: $box--shadow-modal;
      border-radius: 6px;
      overflow: hidden;
      color: $color--text1;
      margin-top: 1px;
      &__list {
         display: grid;
         padding: 8px;
      }
      &__item {
         @include font14_20;
         display: block;
         min-height: 40px;
         min-width: 100%;
         width: max-content;
         padding: 4px 16px;
         display: flex;
         align-items: center;
         cursor: pointer;
         border-radius: 4px;
         transition: $transition-common;
         background-color: transparent;
         &:hover,
         &.focused {
            background-color: $color--black-04;
         }
         .text-2 {
            color: $color--text3;
         }
      }
   }
}

// filter info portal
.filter-info-portal__backdrop {
   position: absolute;
   left: 0;
   top: 0;
   right: 0;
   bottom: 0;
   pointer-events: all;
}
.filter-info-portal__container {
   .filter-info-portal {
      @include font14_20;
      color: $color--text1;
      font-family: $font--regular;
      &__popover {
         box-shadow: $box--shadow-modal;
         border-radius: 6px;
         background-color: $color--light;
         width: 240px;
         padding: 8px;
      }
      &__header {
         display: flex;
         align-items: center;
         justify-content: space-between;
         padding: 10px 16px;
         @include font16;
         font-family: $font--semibold;
         button {
            display: grid;
            padding: 0;
            border: none;
            background-color: transparent;
            cursor: pointer;
            color: $color--text2;
            transition: $transition-common;
            min-width: 16px;
            &:hover {
               color: $color--text1;
            }
         }
      }
      &__body {
         min-height: 40px;
         overflow-y: auto;
         overflow-x: hidden;
         &::-webkit-scrollbar {
            display: none;
         }
      }
      &__footer {
         padding-top: 8px;
         button {
            border: 1px solid $color--primary;
            height: 40px;
            width: 100%;
            padding: 8px 16px;
            cursor: pointer;
            background-color: $color--primary;
            color: $color--light;
            border-radius: 6px;
            transition: $transition-common;
            &:hover {
               background-color: $color--link-hover;
               border-color: $color--link-hover;
            }
            &:disabled {
               background-color: $color--bg;
               border-color: $color--black-10;
               color: $color--text4;
               cursor: default;
            }
         }
      }
   }

   .filter-input {
      &__text {
         @include font14_20;
         border-radius: 4px;
         padding: 8px 16px;
         border: none;
         transition: $transition-common;
         outline: none;
         width: 100%;
         min-height: 40px;
         &:focus,
         &:hover {
            background-color: $color--black-04;
         }
      }
      &__radio-group {
         display: flex;
         flex-direction: column;
         .option-group-wrapper {
            &__content {
               display: grid;
            }
            .ant-radio-wrapper {
               padding: 10px 16px 8px;
               min-height: 40px;
               cursor: pointer;
               width: 100%;
               transition: $transition-common;
               border-radius: 4px;
               overflow: hidden;
               &:hover {
                  background-color: $color--black-04;
               }
            }
         }
      }
   }
}
