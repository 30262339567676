.company-layout {
   .sub-header {
      display: flex;
      margin-bottom: 24px;
      .item {
         // min-width: 150px;
         margin-left: 32px;
      }
      .number {
         color: $color--hyper-link;
         @include font19;
         font-family: $font--semibold;
         margin-bottom: 4px;
      }
      .text {
         color: $color--text2;
         font-family: $font--light;
      }
   }
   .company-cell {
      display: flex;
      align-items: center;
      color: $color--text1;
      @include font16_24;
      font-family: $font--semibold;
      margin: -12px -12px -12px -32px;
      padding: 12px 12px 12px 32px;
      .image {
         width: 40px;
         height: 40px;
         margin-right: 16px;
         object-fit: cover;
      }
      .type {
         @include font12;
         color: $color--text2;
         font-family: $font--regular;
      }
      .lock {
         color: $color--error;
      }
   }
}
// system benefit status
.company-status {
   display: flex;
   justify-content: center;
   border-radius: 4px;
   color: $color--light;
   padding: 2px 10px;
   @include font14_20;

   &.actived {
      background: $color--info;
   }

   &.locked {
      background: $color--warning;
   }
}
