@mixin font12 {
   font-size: 12px;
   line-height: 16px;
}
@mixin font14 {
   font-size: 14px;
   line-height: 20px;
}
@mixin font14_24 {
   font-size: 14px;
   line-height: 24px;
}
@mixin font14_20 {
   font-size: 14px;
   line-height: 20px;
}
@mixin font16 {
   font-size: 16px;
   line-height: 20px;
}
@mixin font16_24 {
   font-size: 16px;
   line-height: 24px;
}
@mixin font20 {
   font-size: 20px;
   line-height: 32px;
}
@mixin font24 {
   font-size: 24px;
   line-height: 32px;
   letter-spacing: 0.005em;
}
@mixin font19 {
   font-size: 19px;
   line-height: 24px;
}
@mixin common-display {
   display: flex;
   justify-content: space-between;
   align-items: center;
}
@mixin common-align-display {
   display: flex;
   align-items: center;
}
@mixin common-justify-display {
   display: flex;
   justify-content: space-between;
}
.common-display {
   display: flex;
   justify-content: space-between;
   align-items: center;
}
.common-layout {
   width: 100%;
   // box-shadow: -1px 0px 0px rgba(0, 0, 0, 0.1);
   z-index: 1;
}
.header-container {
   @include common-display;
   height: 88px;
   width: 100%;
   padding: 24px 32px;
   .title-group {
      @include common-align-display;
      @include font24;
      svg {
         margin-right: 12px;
      }
      .title {
         color: $color--text1;
         font-family: $font--semibold;
      }
      .number {
         color: $color--text3;
         font-family: $font--light;
         margin-left: 8px;
      }
   }
   // .button-group {
   //    @include common-align-display;
   //    color: $color--light;
   //    background: $color--link-hover;
   //    border-radius: 6px;
   //    border: none;
   //    padding: 10px 20px;
   //    cursor: pointer;
   //    .label {
   //       @include font14;
   //       margin-left: 8px;
   //    }
   // }
}
.button-group {
   @include common-align-display;
   color: $color--light;
   background: $color--button;
   border-radius: 6px;
   border: none;
   padding: 10px 20px;
   transition: $transition-common;
   cursor: pointer;
   .label {
      @include font14;
      // margin-left: 8px;
   }
   svg {
      margin-right: 8px;
   }
   &:hover {
      background: $color--link-hover;
   }
}
.tab-container {
   display: flex;
   align-items: center;
   padding: 0 32px;
   .tab-item {
      color: $color--text3;
      padding: 6px 12px;
      margin-right: 4px;
      cursor: pointer;
      border: 1px solid transparent;
      box-sizing: border-box;
      transform: $transition-common;
      &.active {
         background: $color--bg;
         border: 1px solid $color--gray-border;
         box-sizing: border-box;
         border-radius: 20px;
         transform: $transition-common;
         color: $color--text1;
      }
   }
}

.common-icon {
   cursor: pointer;
}
//
.error-message {
   color: $color--error;
   @include font12;
   margin-top: 5px;
}
.icon-loading {
   padding-right: 12px;
   display: inline-flex;
}
//
.content-loading {
   width: calc(100vw - 334px);
   height: 100vh;
   display: flex;
   align-items: center;
   justify-content: center;
}
.disable-button {
   color: $color--text4 !important;
   cursor: not-allowed !important;
}
.hidden-field {
   display: none;
}

.action-text {
   color: $color--hyper-link;
   margin-bottom: 12px;
   cursor: pointer;
   &.warning {
      color: $color--error;
   }
}
body {
   &.modal-open {
      overflow: hidden;
   }
}
.feature-updating {
   width: calc(100% - 334px);
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   .title {
      margin-top: 12px;
      color: $color--text3;
   }
}
.text-bold {
   font-family: $font--semibold;
   @include font16;
   letter-spacing: 0.005em;
}
.locked {
   color: $color--error;
}
.text-report {
   color: $color--info;
}

//loading
.flexi-loading {
   position: absolute;
   left: 0;
   top: 0;
   right: 0;
   bottom: 0;
   display: flex;
   align-items: center;
   justify-content: center;
   z-index: 999999;
   cursor: progress;
   background-color: rgba(255, 255, 255, 0.4);
   margin-left: 334px;
}
.loading-has-right-bar {
   .flexi-loading {
      margin-right: 366px;
   }
}
.actions-column {
   min-width: 90px;
}
.modal-loading {
   &.flexi-loading {
      left: 50%;
      top: 50%;
      right: 50%;
      bottom: 50%;
      margin-left: 0;
      display: block;
   }
}
