//company details
.company-details-layout {
   display: flex;
   .content-left {
      width: calc(100% - 366px);
      &.right-bar-hide {
         width: 100%;
      }
      .header {
         display: flex;
         align-items: center;
         .icon-tab {
            margin-left: 24px;
            cursor: pointer;
         }
      }
      .name {
         margin-right: auto;
      }
      // .ant-btn.button-add {
      //    @include font16;
      //    color: $color--light;
      //    padding: 0 16px;
      //    border: none !important;
      //    border-radius: 6px;
      //    background: $color--button;
      //    height: 40px;
      //    display: flex;
      //    align-items: center;
      //    svg {
      //       margin-left: 10px;
      //    }
      //    &:hover {
      //       border: none;
      //    }
      // }
      .tab-content {
         margin-top: 24px;
      }
   }
   .content-right {
      width: 366px;
      height: 100vh;
      position: sticky;
      top: 0;
      padding: 24px 32px;
      box-shadow: -1px 0px 0px rgba(0, 0, 0, 0.1);
      overflow: scroll;
      transition: $transition-common;
      &.right-bar-hide {
         transition: $transition-common;
         width: 0;
         padding: 0;
      }
      .header {
         display: flex;
         align-items: center;
         img {
            margin-right: 16px;
            object-fit: cover;
         }
      }
      .company-name {
         font-family: $font--semibold;
         @include font16_24;
         white-space: nowrap;
         overflow: hidden;
         text-overflow: ellipsis;
         width: 250px;
      }
      .upload-image {
         color: $color--primary;
      }
      .logo-company {
         width: 48px;
         height: 48px;
      }
      .info {
         padding: 30px 0 4px 0;
         border-bottom: 1px solid $color--gray-border;
      }
      .representative {
         padding: 16px 0 4px 0;
         border-bottom: 1px solid $color--gray-border;
      }
      .row-item {
         display: flex;
         width: 100%;
         .label {
            width: 120px;
            color: $color--text3;
            padding-right: 12px;
            margin-right: auto;
            margin-bottom: 12px;
         }
         .text {
            width: calc(100% - 120px);
         }
      }
      .edit {
         color: $color--primary;
         cursor: pointer;
         margin-top: 16px;
      }
   }

   // benifitchemes
   .benifitschemes-container {
      .no-schemes {
         box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.1);
         padding: 24px 32px;
      }
      .title {
         color: $color--text3;
         margin-bottom: 16px;
      }
      .schemes {
         display: flex;
         align-items: flex-start;
         padding: 24px 32px;
         box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.1);
         position: relative;
         img {
            margin-right: 24px;
            object-fit: cover;
         }
         &:hover {
            .shemes-actions-container.actions-row {
               display: block;
            }
         }
      }
      .shemes-actions-container {
         margin-left: auto;
      }
      .name {
         @include font16;
         font-family: $font--semibold;
         display: flex;
         align-items: center;
         svg {
            margin-left: 8px;
            // cursor: pointer;
         }
      }
      .date {
         @include font12;
         color: $color--text2;
         margin: 4px 0 8px 0;
      }
      .days-left {
         color: $color--error;
      }
      .packages {
         padding-left: 12px;
         border-left: 1.5px solid $color--gray-border;
      }
      .package {
         font-family: $font--light;
         margin-bottom: 4px;
      }
      .content-loading {
         width: initial;
         // height: 60vh;
         height: calc(100vh -122px);
      }
   }
   // financial year
   .status-financial-year {
      max-width: 60px;
      padding: 0 4px;
      color: $color--light;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      min-height: 24px;
      align-items: center;
      &.new {
         background: $color--success;
      }
      &.current {
         background: $color--info;
      }
      &.old {
         background: $color--error;
      }
   }
}
.with-actions-row {
   min-width: 70px;
}
.desciption-width {
   max-width: 150px;
}
.ant-btn.button-add {
   @include font16;
   color: $color--light;
   padding: 0 16px;
   border: none !important;
   border-radius: 6px;
   background: $color--button;
   height: 40px;
   display: flex;
   align-items: center;
   svg {
      margin-left: 10px;
   }
   &:hover {
      border: none;
   }
}
.edit-financial-year {
   color: $color--text3;
   cursor: pointer;
   &:hover {
      color: $color--primary;
   }
}
.start-financial-year {
   color: $color--text3;
   cursor: pointer;
   margin-right: 16px;
}
.no-start-financial-year {
   color: transparent;
   margin-right: 16px;
}
.icon-delete {
   margin-left: 16px;
   cursor: pointer;
   &:hover {
      color: $color--error;
   }
}

.start-financial-task {
   padding: 6px 0;
   svg {
      margin-right: 12px;
   }
   display: flex;
   align-items: center;
}
.start-finacial-year-modal {
   margin-bottom: 30px;
   .flexi-modal__body {
      margin-bottom: 24px;
   }
   .link-task {
      color: $color--light;
      background: $color--button;
      border-radius: 6px;
      padding: 10px 20px;
      margin-left: 12px;
   }
}
//level
.level-container {
   .content-loading {
      width: initial;
      // height: 60vh;
      height: calc(100vh - 120px);
   }
   .level-row {
      // padding: 18px 32px;
      // display: flex;
      // align-items: center;
      // box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.1);

      .name {
         @include font16;
         font-family: $font--semibold;
         margin-bottom: 4px;
      }
      .position {
         color: $color--text2;
      }
      .budget {
         background: $color--success;
         border-radius: 16px;
         margin-left: auto;
         padding: 6px 12px;
         color: $color--light;
         min-width: 86px;
         text-align: center;
         margin-right: 32px;
      }
   }
   .no-level {
      box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.1);
      padding: 24px 32px;
      .title {
         color: $color--text3;
         margin-bottom: 16px;
      }
   }
   .no-eidt {
      margin-left: 32px;
   }
}
.flexi-modal__body.level {
   margin-bottom: 24px;
   .level-info {
      background: $color--button;
      border-radius: 6px;
      padding: 22px 24px;
      .info-row {
         display: flex;
         align-items: center;
         .label {
            color: rgba(255, 255, 255, 0.7);
            min-width: 112px;
            margin-right: 12px;
         }
         .text {
            color: white;
         }
      }
   }
   .edit-info-level {
      .label {
         font-family: $font--semibold;
         margin-bottom: 8px;
      }
      .ant-row {
         margin-bottom: 12px;
      }
   }
   .scheme-item {
      display: flex;
      align-items: center;
      margin-top: 24px;
      padding: 10px 16px;
      border: 1px solid $color--gray-border;
      box-sizing: border-box;
      border-radius: 6px;
      cursor: pointer;
      background: $color--bg;
      transition: $transition-common;
      &.open {
         border-radius: 6px 6px 0 0;
      }
      .left {
         display: flex;
         align-items: center;
         color: $color--text4;
         .title {
            font-family: $font--semibold;
            color: $color--text1;
            @include font16;
            margin-left: 12px;
         }
      }
      .right {
         margin-left: auto;
         color: $color--button;
         font-family: $font--medium;
      }
   }
   .package-item {
      border-left: 1px solid $color--gray-border;
      border-right: 1px solid $color--gray-border;
      border-bottom: 1px solid $color--gray-border;
      padding: 10px 16px;
      transition: $transition-common;
      &:last-child {
         border-radius: 0 0 6px 6px;
      }
      .content-right {
         display: flex;
         flex-wrap: wrap;
      }
   }
   .total-package {
      min-width: 56px;
      display: flex;
      align-items: center;
      justify-content: end;
      color: $color--button;
      margin-left: 12px;
   }
   .ant-input-number.input-number {
      &.ant-input-number-focused {
         box-shadow: none;
      }
      &:focus {
         box-shadow: none;
      }
      input:hover {
         background: rgba(0, 0, 0, 0.04);
         border-radius: 6px;
      }
      .ant-input-number-handler-wrap {
         display: none;
      }
      &.percent {
         width: 60px;
      }
      border: none;
   }
   .inc-dec-percent {
      display: flex;
      align-items: center;
      margin-left: 12px;
      cursor: pointer;
      .icon {
         color: $color--text4;
         background: white;
         border-radius: 6px;
         padding: 9px;
         display: inline-flex;
         &.disabel {
            color: $color--gray-border;
            &:hover {
               color: $color--gray-border;
            }
         }
         &:hover {
            color: #525252;
            background: rgba(0, 0, 0, 0.04);
         }
      }
   }
}
.positions-of-level {
   display: grid;
   grid-gap: 4px;
}

.scheme-item {
   display: flex;
   align-items: flex-start;
   padding: 24px 32px;
   position: relative;
   img {
      margin-right: 24px;
   }
   .logo-scheme {
      width: 40px;
      height: 40px;
      object-fit: cover;
   }
   &:hover {
      .shemes-actions-container.actions-row {
         display: block;
      }
   }
   .shemes-actions-container {
      margin-left: auto;
   }
   .name {
      @include font16;
      font-family: $font--semibold;
      display: flex;
      align-items: center;
      letter-spacing: 0.005em;
      svg {
         margin-left: 8px;
      }
   }
   .date {
      @include font12;
      color: #155df5;
      margin: 4px 0 8px 0;
   }
   .days-left {
      color: $color--text3;
   }
   .packages {
      padding-left: 12px;
      border-left: 1.5px solid $color--gray-border;
   }
   .package {
      font-family: $font--light;
      margin-bottom: 4px;
   }
   .content-loading {
      width: initial;
      height: 60vh;
   }
}
