.ant-popconfirm.flexi-popconfirm {
   padding-top: 6px;
   .ant-popover-arrow {
      display: none;
   }
   .ant-popover-inner {
      background: #ffffff;
      box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.16), 0px 4px 16px rgba(38, 52, 70, 0.16);
      // box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
      border-radius: 16px;
   }
   .ant-popover-inner-content {
      padding: 32px;
      max-width: 350px;
   }
   .ant-popover-message {
      padding: 0px 0px 20px;
      font-size: 12px;
      line-height: 16px;
      color: $color--text1;
      .ant-popover-message-title {
         padding: 0;
         .title {
            @include font24;
            font-family: $font--semibold;
            color: $color--error;
         }
         .message {
            margin-top: 8px;
            @include font14_20;
            color: $color--text1;
            letter-spacing: -0.005em;
         }
      }
   }
   .ant-popover-buttons {
      display: flex;
      justify-content: space-between;
      margin: 0;
      button {
         margin-left: 0;
      }
      .ant-btn-primary {
         background-color: $color--error;
         border-color: $color--error;
         &:hover {
            background-color: $color--error;
            border-color: $color--error !important;
         }
      }
   }
}
