.profile-layout {
   padding: 24px;
   display: flex;
   .name {
      @include font24;
      font-family: $font--semibold;
   }
   .email {
      margin: 4px 0 12px 0;
      color: $color--text2;
      @include font14;
   }
   .button-row {
      display: flex;
      &.form {
         justify-content: space-between;
      }
   }
   .button-profile {
      padding: 10px 20px;
      border: 1px solid $color--hyper-link;
      color: $color--hyper-link;
      box-sizing: border-box;
      border-radius: 6px;
      margin-right: 16px;
      cursor: pointer;
      &.edit-profile:hover {
         background: $color--info-bg;
      }
      &.change-password {
         border: 1px solid $color--error;
         color: $color--error;
         &:hover {
            background: $color--error-bg;
         }
      }
      &.cancel {
         color: $color--text1;
         border: 1px solid $color--gray-border;
         margin-right: 0;
      }
      &.submit {
         color: $color--light;
         background: $color--primary;
         border: none;
         margin-right: 0;
      }
   }
   .separation {
      margin-top: 24px;
   }
   .summary {
      background: $color--info-bg;
      margin: 32px 0 40px 0;
      border-radius: 6px;
      padding: 24px 32px;
      display: flex;
      .item {
         margin-right: 32px;
         &:last-child {
            margin-right: 0;
         }
      }
      .value {
         color: $color--primary;
         font-family: $font--semibold;
         margin-bottom: 4px;
      }
      .summary-label {
         font-family: $font--light;
      }
   }
   .label {
      color: $color--text3;
      margin-bottom: 4px;
      @include font14;
   }
   .text {
      margin-bottom: 16px;
   }
   .left {
      width: 132px;
      .avatar {
         width: 102px;
         height: 102px;
         border-radius: 50%;
         object-fit: cover;
      }
   }
   .right {
      width: calc(100% - 140px);
   }
   .form-profile {
      width: 301px;
      margin-top: 32px;
      transition: $transition-common;
   }
   .profile {
      transition: $transition-common;
   }
   .title-input {
      margin: 0 0 8px 0;
      font-family: $font--semibold;
   }
   .upload-content {
      width: 102px;
      text-align: center;
      margin-top: 40px;
      font-family: $font--medium;
   }
}
