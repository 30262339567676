.input-item {
    .required-notation {
        color: $color--error;
        margin-left: 4px;
    }
    .error-message {
        transform: scaleY(1);
        transform-origin: top;
        transition: all 0.2s;
        height: 15px;
        &.hide {
            transform: scaleY(0);
            height: 0px;
        }
    }
    .ant-input,
    .ant-input-number,
    .ant-select .ant-select-selector,
    .ant-radio-group {
        min-height: 40px;
        @include font14_20;
        width: 100%;
        border: 1px solid $color--gray-border;
        box-sizing: border-box;
        border-radius: 6px;
        box-shadow: none !important;
        display: flex;
        align-items: center;
    }
    .ant-input-number {
        .ant-input-number-input-wrap {
            width: inherit;
        }
    }
    .ant-input-affix-wrapper.ant-input-password {
        line-height: 20px;
        .ant-input {
            min-height: initial;
        }
    }
    .ant-input-affix-wrapper > input.ant-input {
        border: none !important;
    }
    .ant-select-dropdown {
        border-radius: 8px;
    }
    .ant-select-single {
        width: 100%;
        .ant-select-selector {
            .ant-select-arrow {
                top: 50%;
                width: 16px;
                height: 16px;
            }
        }
    }
    .ant-select-item-option {
        padding: 8px 12px;
    }
    .custom-input-vertical {
        padding: 10px 16px;
        border-radius: 6px;
        &:hover,
        &:focus {
            border-color: $color--hyper-link;
            box-shadow: none;
        }
    }
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        color: $color--black;
        font-weight: unset;
        background-color: $color--light;
    }
    .ant-radio-group {
        border: none;
        label {
            margin-right: 24px;
        }
    }
}
.ant-btn:hover,
.ant-btn:focus {
    border: none;
}
.file-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    border-radius: 6px;
    border: 1px dashed $color--gray-border;
    background: $color--bg;
    padding-left: 12px;
    padding-right: 4px;
    // max-width: 485px;
    .file-names {
        @include font14_24;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .action-button {
        padding: 4px 12px;
        background-color: $color--bg;
        cursor: pointer;
        border-radius: 6px;
        height: 32px;
        min-width: 50px;
        &.remove {
            color: $color--error;
            &:hover {
                color: $color--light;
                background-color: $color--error;
            }
        }
        &.upload {
            color: $color--success-bg;
            &:hover {
                color: $color--light;
                background-color: $color--success;
            }
        }
    }
}

.input-file-container {
    position: relative;
    .avatar {
        position: absolute;
        top: 8px;
        left: 8px;
        width: 64px;
        height: 64px;
        object-fit: cover;
    }
    &.multiple {
        max-height: 180px;
        overflow-y: scroll;
        border-top: 1px solid $color--gray-border;
        border-bottom: 1px solid $color--gray-border;
        border-radius: 8px;
        // -ms-overflow-style: none; /* for Internet Explorer, Edge */
        // scrollbar-width: none; /* for Firefox */
        // &::-webkit-scrollbar {
        //     display: none; /* for Chrome, Safari, and Opera */
        // }
        .file-item {
            border-top: 1px solid $color--gray-border;
            border-right: 1px solid $color--gray-border;
            border-left: 1px solid $color--gray-border;
            display: flex;
            justify-content: space-between;
            padding: 8px 16px;
            &:first-child {
                border-top: none;
                border-radius: 8px 8px 0 0;
            }
            &.hide-upload {
                &:last-child {
                    border-radius: 0 0 8px 8px;
                }
                &:only-child {
                    border-radius: 8px;
                }
            }
            &__img {
                width: 40px;
                height: 40px;
                object-fit: cover;
                margin-right: 16px;
            }
            &__name {
                display: flex;
                align-items: center;
            }
        }
        a .file-item__name {
            color: $color--text1;
        }
        .ic-remove {
            display: grid;
            color: $color--error;
            cursor: pointer;
        }
    }
}
.input-file-for-modal {
    .box-input {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 80px;
        border-radius: 6px;
        border: 1px dashed $color--gray-border;
        background: $color--bg;
        padding-left: 12px;
        padding-right: 4px;
        cursor: pointer;
    }
    .button-input {
        display: flex;
        align-items: center;
        color: $color--link-hover;
    }
    .text-input {
        color: $color--button;
        font-family: $font--medium;
        margin-left: 16px;
    }
    &.multiple {
        .box-input {
            border-radius: 0 0 6px 6px;
            border: 1px solid $color--gray-border;
            border-bottom: none;
            height: 40px;
        }
    }
}
//input emails
.container-input-email {
    display: inline-flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    width: 100%;
    border: 1px solid $color--gray-border;
    border-radius: 6px;
    padding: 0px 12px 7px 12px;
    font-size: 14px;
    line-height: 24px;

    max-height: 200px;
    overflow-y: auto;
    transition: $transition-common;

    .input-emails {
        margin-top: 7px;
        flex-grow: 1;
        height: 24px;
        border: 1px solid transparent;

        &:focus {
            outline: none !important;
            border: 1px solid transparent;
        }
    }

    .email-container {
        position: relative;
        margin-right: 6px;
        margin-top: 7px;

        .wrapper-email {
            background-color: $color--gray-border;
            border-radius: 4px;
            padding: 0px 8px;
            display: inline-flex;
            align-items: center;

            .wrapper-icon-cancel {
                display: inline-flex;
                width: 16px;
                height: 16px;
                align-items: center;
                justify-content: center;
                padding: 2px;
                margin-left: 4px;
                cursor: pointer;
                border-radius: 50%;
                transition: $transition-common;

                .ic-cancel path[stroke] {
                    stroke: $color--text2;
                }

                &:hover {
                    background-color: $color--info-bg;

                    .ic-cancel path[stroke] {
                        stroke: $color--text1;
                    }
                }
            }
        }
    }
}
