.main-table-statistic {
   .budget {
      // margin-right: 48px;
      .green {
         color: $color--success;
      }
      .red {
         color: $color--error;
      }
   }
}
