$z-index--header: 999;

@mixin disable-select {
   -webkit-touch-callout: none;
   /* iOS Safari */
   -webkit-user-select: none;
   /* Safari */
   -khtml-user-select: none;
   /* Konqueror HTML */
   -moz-user-select: none;
   /* Old versions of Firefox */
   -ms-user-select: none;
   /* Internet Explorer/Edge */
   user-select: none;
   /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

.file-previewer-container {
   position: fixed;
   left: 0;
   top: 0;
   bottom: 0;
   right: 0;
   z-index: $z-index--header;

   .nav-back {
      left: 0;
   }
   .nav-next {
      right: 0;
   }

   .nav-next,
   .nav-back {
      position: absolute;
      top: 0;
      bottom: 0;
      padding: 14px;
      display: flex;
      align-items: center;
      cursor: pointer;
      z-index: 9;
      @include disable-select;
      button {
         display: grid;
         background-color: $color--line;
         border-radius: 50%;
         border: none;
         color: $color--text1;
         padding: 12px;
      }
   }

   .file-previewer-current-page {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding-bottom: 14px;
      display: flex;
      justify-content: center;
      pointer-events: none;
      z-index: 9;
      span {
         @include font14-20;
         padding: 0px 16px;
         border-radius: 12px;
         background-color: $color--text1_70;
         color: $color--light;
         pointer-events: all;
         @include disable-select;
      }
   }

   .file-preview-body {
      padding: 56px 68px 1px;
      height: 100%;
      width: 100%;
      pointer-events: none;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 0;
      position: relative;
      * {
         pointer-events: all;
      }
      .video-preview,
      .image-preview {
         pointer-events: none;
         .video-preview-wrapper,
         .lazy-image-img {
            pointer-events: all;
         }
      }
      .e-loading .circle-loading {
         border-color: $color--light_20;
         border-left-color: $color--light;
      }
      .image-preview .lazy-image-img,
      .video-preview .video-preview-wrapper,
      .doc-preview-wrapper,
      .doc-preview {
         border-radius: 8px;
      }
   }
}

// preview header
.file-preview-header {
   position: fixed;
   z-index: 9999;
   top: 0;
   left: 0;
   right: 0;
   height: 56px;
   background-color: $color--text1;
   color: $color--light;
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 8px 142px;
   @include font14-20;
   &__close {
      position: absolute;
      left: 14px;
   }
   &__page-number {
      position: absolute;
      left: 68px;
      display: grid;
      padding: 4px 8px;
      border-radius: 6px;
      background-color: $color--light_25;
      letter-spacing: 0.02em;
      cursor: default;
   }
   &__actions {
      position: absolute;
      right: 14px;
   }
   .square-button {
      color: $color--light;
      padding: 12px;
      background-color: transparent;
      border: none;
      &:hover {
         background-color: $color--light_15;
      }
      svg {
         color: $color--light;
      }
   }
   .file-name {
      @include font14-20;
      line-height: 20px;
      text-align: center;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
   }
   &__actions {
      &:disabled {
         cursor: not-allowed;
         svg {
            color: $color--light_50;
         }
         &:hover {
            background-color: transparent;
         }
      }
   }
}

.file-previewer-backdrop {
   background-color: $color--text1_70;
   position: absolute;
   left: 0;
   top: 0;
   bottom: 0;
   right: 0;
   z-index: -1;
}

.image-preview {
   width: 100%;
   height: 100%;
   overflow: hidden;
   justify-content: center;
   align-items: center;
   pointer-events: none;
   display: flex;
   align-items: center;
   justify-content: center;
   @include disable-select;
   .lazy-image-img {
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
      object-fit: contain;
      pointer-events: all;
      overflow: hidden;
   }
}
.no-preview {
   background-color: $color--light;
   border-radius: 16px;
   padding: 24px;
   max-width: 100%;
   max-height: 100%;
   width: 400px;
   @include font16;
   display: flex;
   flex-direction: column;
   align-items: center;
   text-align: center;
   margin-bottom: 56px;
   button {
      svg {
         margin-right: 8px;
      }
      background-color: $color--light;
      border-radius: 8px;
      border: 1px solid $color--line;
      padding: 4px 12px;
      margin-top: 16px;
   }

}
.video-preview {
   max-width: 100%;
   width: 100%;
   overflow: hidden;
   height: 100% !important;
   position: relative;
   display: flex;
   align-items: center;
   justify-content: center;
   .video-preview-wrapper {
      overflow: hidden;
   }
}
.audio-preview {
   margin-bottom: 56px;
}
.pdf-preview {
   pointer-events: none !important;
   .pdf-viewer__container {
      &::-webkit-scrollbar {
         display: none;
      }
   }
   .pdf-preview-loading {
      @include font14-20;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      color: $color--light;
      position: fixed;
      text-align: center;
      white-space: nowrap;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
         padding-top: 64px;
      }
   }
}

.doc-preview-wrapper {
   z-index: 1500;
   background-color: $color--light;
   width: 100%;
   height: 100%;
   .doc-preview {
      border: none;
      width: 100%;
      height: 100%;
   }
}

.pdf-viewer {
   width: 100%;
   height: 100%;
   position: relative;
   &__container {
      height: 100%;
      display: flex;
      // justify-content: center;
      position: relative;
      overflow: auto;
      width: max-content;
      margin: 0px auto;
      max-width: 100%;
      pointer-events: all;
   }
   // preview body
   &__document {
      height: max-content;
      position: relative;
      padding: 8px 0px;
      margin-right: auto;
      margin-left: auto;

      .text-mark {
         display: inline !important;
         padding: 0 !important;
         background-color: #ff885c;
         color: initial;
      }
   }
   // a page
   &__page {
      padding: 8px 0px;
      width: max-content;
   }

   // tools
   &__tools {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      padding: 24px 16px;
      pointer-events: none !important;
      z-index: 1;
      @include font14-20;
      line-height: 20px;

      &--container {
         display: flex;
         flex-direction: column;
         align-items: center;
      }
      .square-button {
         margin-right: 4px;
         color: $color--light;
         background-color: transparent;
         border: none;
         &:hover,
         &.active {
            background-color: $color--light_10;
         }
         &:disabled {
            background-color: transparent;
            cursor: not-allowed;
            color: $color--light_50;
         }
      }
   }
   &__tool-bar {
      pointer-events: all;
      display: flex;
      align-items: center;
      padding: 4px;
      border-radius: 8px;
      background-color: $color--text1_80;
      color: $color--light;
      margin-top: 4px;
      width: max-content;
   }

   // navigation tool
   &__navigation {
      display: flex;
      align-items: center;
      .square-button {
         &.next {
            svg {
               transform: rotate(180deg);
            }
         }
      }
      input {
         background-color: $color--light_10;
         border: none;
         border-radius: 6px;
         @include font14-20;
         line-height: 20px;
         padding: 6px;
         height: 32px;
         width: 40px;
         text-align: center;
         margin-right: 4px;
      }
      span {
         color: $color--light_50;
         padding: 6px 8px;
         cursor: default;
      }
   }
   // scale tool
   &__scale {
      .square-button {
         margin: 0;
      }
   }
   // search tool
   .search-btn {
      margin-right: 0;
      margin-left: 4px;
   }
   &__search {
      display: flex;
      align-items: center;
      input {
         background-color: $color--light_10;
         border-radius: 6px;
         padding: 6px 8px;
         width: 164px;
         height: 32px;
         @include font14-20;
         line-height: 20px;
         color: $color--light;
         border: none;
      }
      span {
         margin-left: 4px;
         @include font14-20;
         line-height: 20px;
         color: $color--light_50;
         display: block;
         padding: 6px 8px;
      }
      .square-button {
         margin-right: 0;
         margin-left: 4px;
         svg {
            transform: rotate(-90deg);
         }
         &.next {
            svg {
               transform: rotate(90deg);
            }
         }
      }
   }
}

.pdf-viewer__scale--dropdown.elite-dropdown {
   &.popover.show {
      z-index: calc(#{$z-index--header} * 2);
      border-radius: 8px;
   }
   .pdf-viewer__scale--options {
      padding: 8px 0px;
      min-width: 180px;
      max-height: calc(100vh - 24px);
      overflow: auto;
      .select-item {
         padding: 0px 8px;
         cursor: pointer;
         .select-box {
            padding: 4px 8px;
            justify-content: space-between;
            border-radius: 6px;
            svg {
               margin-left: 8px;
               margin-right: 0;
            }
         }
      }
      .select-item:hover {
         .select-box {
            background-color: $color--text1_05;
         }
      }
   }
}
