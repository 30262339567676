.activate-container {
   .login-title {
      margin-bottom: 8px;
   }
   .forgot-password {
      margin-bottom: 32px;
   }
   .btn-row {
      display: flex;
      justify-content: space-between;
   }
   .btn-login {
      padding: 10px 40px;
   }
   .btn-decline {
      border: 1px solid $color--gray-border;
      border-radius: 6px;
      background: $color--light;
      color: $color--text1;
      padding: 9px 20px;
   }
   .ant-form-item-explain-error {
      margin-bottom: 4px;
   }
}
