.request-status {
   display: flex;
   justify-content: center;
   border-radius: 4px;
   color: $color--light;
   padding: 4px 8px;
   // width: fit-content;
   // margin-left: 16px;
   min-width: 68px;
   @include font12;
   &.new {
      background: $color--hyper-link;
   }
   &.denied {
      background: $color--error;
   }
   &.approved {
      background: $color--warning;
   }
   &.completed {
      background: $color--success;
   }
}
.request-title {
   cursor: pointer;
}
.show-note {
   width: 100%;
   width: 350px;
   text-overflow: ellipsis;
   overflow: hidden;
   white-space: nowrap;
   color: $color--text2;
   @include font12;
}
.update-request-modal {
   .request-name {
      color: $color--link-hover;
      font-family: $font--semibold;
      @include font24;
      letter-spacing: 0.005em;
   }
   .content-box {
      // display: flex;
      background: $color--bg;
      border: 1px solid $color--gray-border;
      border-radius: 6px;
      padding: 16px 24px;
      margin-bottom: 24px;
      @include font14;
      &__row {
         display: flex;
         margin-bottom: 12px;
         &:last-child {
            margin-bottom: 0;
         }
      }
      &__label {
         color: $color--text3;
         width: 115px;
      }
      &__value {
         margin-left: 12px;
         font-family: $font--semibold;
         width: calc(100% - 115px);
         padding-left: 8px;
      }
      .scheme-name {
         color: $color--hyper-link;
      }
   }
   .flexi-modal__footer {
      .approve-btn {
         margin-left: 16px;
      }
   }
   .notes {
      font-family: $font--semibold;
      margin-bottom: 4px;
      .star {
         color: $color--error;
      }
   }
   .notes-input {
      margin-bottom: 24px;
   }
}
