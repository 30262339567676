.login-container {
   display: flex;
   justify-content: center;
   height: 100vh;
   align-items: center;
   .login-content {
      width: 1366px;
      display: flex;
      padding: 32px 0 32px 0;
      height: 100vh;
      max-height: 768px;
   }
   .bg-login {
      @include font14;
      letter-spacing: -0.005em;
      background-image: url("../assets/images/login-img.svg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      object-fit: cover;
      max-width: 100%;
      max-height: 704px;
      width: 100%;
      height: 100%;
      border-radius: 6px;
   }
   .left {
      width: 588px;
      padding: 32px 143px 0 143px;
      display: flex;
      flex-direction: column;
      .input-item {
         width: 302px;
      }
   }
   .right {
      width: calc(100vw - 588px);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 10px;
   }
   .login-title {
      @include font24;
      font-family: $font--semibold;
      margin: 64px 0 24px 0;
   }
   .btn-login {
      width: 100%;
      padding: 10px 0;
      color: $color--light;
      background: $color--button;
      border: none;
      border-radius: 6px;
      margin: 0 0 24px 0;
      @include font14_20;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      &:disabled {
         background: rgba(22, 22, 22, 0.05);
         color: $color--text2;
      }
   }
   .input-password {
      margin-top: 24px;
   }
   .forgot-password {
      color: $color--text2;
   }
   .left-footer {
      display: flex;
      justify-content: space-between;
      color: $color--text2;
      margin-top: auto;
      align-items: center;
      .text {
         margin-bottom: 3px;
      }
   }
   .language-dropdown {
      display: inline-flex;
      .input-item {
         width: 100px;
         .ant-select .ant-select-selector {
            border: none;
            color: $color--text2;
         }
      }
      .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
      .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
         margin-top: 1px;
      }
   }
   .back-to-link {
      color: $color--text2;
      display: flex;
      align-items: center;
      span {
         display: flex;
         margin-right: 8px;
      };
   }
}
@media (max-width: 1276.98px) {
   .login-container {
      .left {
         width: 350px;
         padding: 24px 14px 24px 24px;
         .input-item {
            width: 100%;
         }
      }
      .right {
         width: calc(100vw - 350px);
      }
   }
}
