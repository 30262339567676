.criteria-type-container {
   .title {
      @include font14;
      letter-spacing: 0.01em;
      color: $color--text1;
      font-family: $font--semibold;
      margin-bottom: 4px;
   }
}
.criteria-checkbox-type {
   display: flex;
   flex-direction: column;
   .ant-checkbox-group-item {
      padding: 10px 16px;
      margin-right: 0;
      border-top: 1px solid $color--gray-border;
      border-right: 1px solid $color--gray-border;
      border-left: 1px solid $color--gray-border;
      &:first-child {
         border-radius: 6px 6px 0 0;
      }
      &:last-child {
         border-bottom: 1px solid $color--gray-border;
         border-radius: 0 0 6px 6px;
      }
   }
   .criteria-row {
      margin: 0;
   }
   .ant-col.checkbox-col {
      height: 40px;
      padding: 0 16px;
      margin-right: 0;
      border-top: 1px solid $color--gray-border;
      border-right: 1px solid $color--gray-border;
      border-left: 1px solid $color--gray-border;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .input-item {
         max-height: 38px;
         width: 100%;
      }
      .icon {
         display: grid;
      }
      .icon-remove {
         color: $color--error;
         cursor: pointer;
      }
      &:first-child {
         border-radius: 6px 6px 0 0;
      }
      &:last-child {
         border-bottom: 1px solid $color--gray-border;
         border-radius: 0 0 6px 6px;
      }
      .checkbox-item-label {
         border: none;
         width: 100%;
      }
      .add-option {
         display: flex;
         align-items: center;
         @include font14;
         color: $color--hyper-link;
         padding: 10px 0;
         cursor: pointer;
         .icon-add-option {
            margin-right: 12px;
         }
      }
      .ant-radio-wrapper {
         align-items: center;
         width: 100%;
      }
   }
   margin-bottom: 24px;
}
