.toggle-switch {
    .switch {
        position: relative;
        display: inline-block;
        width: 28px;
        height: 16px;
    }

    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $color--gray-border;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        &.disabled {
            pointer-events: none;
        }
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 12px;
        width: 12px;
        left: 2px;
        bottom: 2px;
        background-color: $color--light;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }
    input:disabled {
        color: $color--text2;
    }

    input:checked + .slider {
        background-color: $color--primary;
    }

    input:focus + .slider {
        box-shadow: 0 0 1px $color--primary;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(11px);
        -ms-transform: translateX(11px);
        transform: translateX(11px);
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }
}
