/**
* color
*/
$color--text1: #161616;
$color--text1_05: rgba(
   $color: $color--text1,
   $alpha: 0.05,
);
$color--text1_04: rgba(
   $color: $color--text1,
   $alpha: 0.04,
);
$color--text1-70: rgba(
   $color: $color--text1,
   $alpha: 0.7,
);
$color--text1-80: rgba(
   $color: $color--text1,
   $alpha: 0.8,
);
$color--text2: #525252;
$color--text3: #727272;
$color--text4: #a8a8a8;
$color--light: #ffffff;
$color--light-10: rgba(
   $color: $color--light,
   $alpha: 0.1,
);
$color--light-15: rgba(
   $color: $color--light,
   $alpha: 0.15,
);
$color--light-20: rgba(
   $color: $color--light,
   $alpha: 0.2,
);
$color--light_25: rgba(
   $color: $color--light,
   $alpha: 0.25,
);
$color--light-50: rgba(
   $color: $color--light,
   $alpha: 0.5,
);
$color--primary: #0f62fe;
$color--black: #000000;
$color--bg: #f5f5f5;
$color--gray-border: #e0e0e0;
$color--success: #42be65;
$color--yellow: #f1c21b;
$color--error: #fa4d56;
$color--info: #4589ff;
$color--success-bg: #defbe6;
$color--warning-bg: #fff8e1;
$color--error-bg: #fff1f1;
$color--info-bg: #edf5ff;
$color--link-hover: #0042ed;
$color--button: #155df5;
$color--hyper-link: #155df5;
$color--warning: #ff8946;
$color--line: #d8d8d8;

$color--black-04: rgba(
   $color: $color--black,
   $alpha: 0.04,
);
$color--black-10: rgba(
   $color: $color--black,
   $alpha: 0.1,
);

/**
* border radius
*/
$border--radius-h64: 16px;
$border--radius-h48: 8px;
$border--radius-h40: 8px;
$border--radius-h32: 6px;
$border--radius-h24: 4px;

/**
* shadow
**/
$box--shadow-medium: 0px 2px 8px rgba(0, 0, 0, 0.1), 0px 4px 16px rgba(0, 0, 0, 0.15);
$box--shadow-big: 0px 4px 12px rgba(0, 0, 0, 0.15), 0px 6px 24px rgba(0, 0, 0, 0.2);
$box--shadow-light: 0px 0px 2px rgba(0, 0, 0, 0.2);
$box--shadow-modal: 0px 3px 8px rgba(0, 0, 0, 0.4);
$inner--shadow-line-top: inset 0px 1px 0px rgba(0, 0, 0, 0.08);

/**
* font
**/

$font--regular: "BioSans-Regular";
$font--semibold: "BioSans-SemiBold";
$font--medium: "BioSans-SemiBold";
$font--light: "BioSans-Light";
$font--bold: "BioSans-Bold";

/**
* transition
**/
$transition-common: all 0.3s ease;
