// custom toast
.Toastify__toast-container.cme-toast-container {
   position: fixed;
   z-index: 99999;
   top: 0;
   padding: 0;
   // left: 0;
   right: 0;
   width: 100%;
   margin: 0;
   color: $color--text2;
   pointer-events: none;
   .cme-toast {
      margin: 0px;
      background-color: $color--light;
      min-height: 56px;
      padding: 8px 120px;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12) !important;
      cursor: default;
      pointer-events: all;
      display: flex;
      align-items: center;
      overflow: hidden;
      .Toastify__toast-body {
         margin-left: 32px;
         display: flex;
         width: 100%;
         justify-content: center;
      }
      .toast-content {
         display: flex;
         align-items: center;
         justify-content: center;
         // svg {
         //    margin-left: 12px;
         // }
      }
      .btn-close-toast {
         border: none;
         background: transparent;
         border-radius: 50%;
         display: grid;
         padding: 8px;
         transition: $transition-common;
         &:hover {
            background: $color--bg;
         }
      }
   }
}
.dark-toast {
   min-width: 380px !important;
   top: 48px !important;
}

.toast-content-msg {
   font-size: 14px;
   line-height: 20px;
   color: $color--text2;

   margin-bottom: 0px;
   font-family: $font--regular;
   cursor: default;
   margin-left: 12px;
}

.toast-success {
   border-bottom: 2px solid $color--success !important;
}

.toast-warning {
   border-bottom: 2px solid $color--yellow !important;
}

.toast-error {
   border-bottom: 2px solid $color--error !important;
}

//simple toast

.Toastify__toast-container.simple-toast-container {
   bottom: 0px;
   // left: 0;
   right: 0;
   width: 100%;
   box-sizing: border-box;
   margin: 0;
   padding: 16px 24px 40px;
   list-style: none;
   position: fixed;
   z-index: 9999;
   pointer-events: none;
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;

   .simple-toast {
      cursor: default;
      pointer-events: all;
      display: inline-block;
      max-width: 100%;
      margin-bottom: 16px;
      padding: 8px 16px;
      color: white;
      border-radius: 6px;
      background: #111111;
      min-height: unset;
      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12);
      .simple-toast-body {
         margin: 0;
      }
      .simple-toast-content {
         @include font14;
         display: flex;
         align-items: center;
         span {
            text-align: center;
            white-space: pre-line;
         }
         svg {
            margin-right: 12px;
         }
      }
   }
}

// notification toast
.Toastify__toast.noti-toast {
   position: fixed;
   top: 24px;
   right: 0;
   max-width: 345px;
}
