.notification-layout {
   .header {
      padding: 16px 32px 0 32px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
         @include font24;
         font-family: $font--semibold;
      }
      .mark {
         font-family: $font--semibold;
         cursor: pointer;
         &.read-all {
            pointer-events: none;
            color: $color--text4;
         }
      }
   }
   .item-noti {
      display: flex;
      border-bottom: 1px solid $color--gray-border !important;
      .info {
         margin-left: 12px;
      }
      .title {
         @include font16;
         letter-spacing: 0.005em;
         color: $color--text1;
         font-family: $font--semibold;
      }
      .message {
         @include font14;
         letter-spacing: -0.005em;
         color: $color--text2;
         margin-top: 4px;
      }
      .date {
         margin-left: auto;
      }
   }
   .ellipse {
      width: 8px;
      height: 8px;
      background: $color--error;
      border-radius: 50%;
      margin-top: 8px;
      &.readed {
         background: transparent;
      }
   }
   .no-data {
      padding: 12px 32px;
      color: $color--text2;
   }
}
