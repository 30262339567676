/**
* modal
**/
.ant-modal {
   color: $color--text1;
   @include font14_20;
   letter-spacing: 0.005em;
   top: 40px;
   width: 699px !important;
}
.ant-modal-content {
   overflow: hidden;
   background: #ffffff;
   box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.4);
   border-radius: 16px;
}
.ant-modal-body {
   padding: 0;
   line-height: 24px;
}

/**
* form
**/
.ant-form {
   &-item-label {
      display: flex;
      > label {
         font-family: $font--semibold;
         &.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
            position: absolute;
            left: 100%;
            margin-left: 4px;
         }
         &::after {
            content: none;
         }
      }
   }
   .ant-row {
      .ant-col {
         padding: 0 12px;
      }
      .ant-form-item-control {
         margin-bottom: 0;
         .ant-form-item-explain-error {
            @include font12;
         }
      }
      margin-left: -12px;
      margin-right: -12px;
   }
   .ant-form-item {
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;
   }
}

/**
* button
**/
.ant-btn {
   box-shadow: none;
   color: $color--text1;
   border: 1px solid $color--gray-border;
   background-color: transparent;
   &:hover {
      color: $color--text1;
      border: 1px solid $color--gray-border !important;
      background-color: transparent;
   }
}
.ant-btn-primary {
   color: $color--light;
   border-color: $color--link-hover;
   background-color: $color--link-hover;
   text-shadow: none;
   box-shadow: none;
   &:hover {
      text-decoration: none;
      color: $color--light;
      border-color: $color--link-hover !important;
      background-color: $color--link-hover;
   }
}
.ant-btn-danger {
   color: $color--error;
   border-color: $color--error;
   background-color: $color--light;
   text-shadow: none;
   box-shadow: none;
   &:hover {
      text-decoration: none;
      color: $color--error;
      border-color: $color--error !important;
      background-color: $color--light;
   }
}
.ant-btn-lg {
   height: auto;
   min-height: 40px;
   padding: 4px 16px;
   @include font14_20;
   border-radius: 6px;
}

/**
* datepicker
**/
.ant-picker {
   min-height: 40px;
   width: 100%;
   border-radius: 6px;
}

// dropdown
.ant-dropdown {
   &.custom-dropdown {
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
      min-width: 146px !important;
      .ant-dropdown-menu {
         border-radius: 6px;
         padding: 8px 0;
      }
      &.dark {
         min-width: 224px !important;
         .ant-dropdown-menu {
            background: $color--text1;
         }
         .ant-dropdown-menu-title-content {
            color: white;
         }
         .ant-dropdown-menu-item:hover,
         .ant-dropdown-menu-submenu-title:hover {
            background: rgba(255, 255, 255, 0.1);
         }
      }
      .ant-dropdown-menu-item,
      .ant-dropdown-menu-submenu-title {
         padding: 10px 20px;
      }
   }
}

/**
* radio
**/
.ant-radio {
   color: $color--text1;
   padding: 3px 1px;
   @include font14_20;
   position: initial;
}
.ant-radio-wrapper {
   @include font14_20;
   color: $color--text1;
   align-items: flex-start;
   margin-right: 0px;

   //fix radio
   display: flex;
   align-items: center;
}
span.ant-radio + * {
   padding-right: 0;
   padding-left: 12px;
}
.ant-radio-input:focus + .ant-radio-inner,
.ant-radio-wrapper:hover .ant-radio-inner,
.ant-radio:hover .ant-radio-inner {
   border-color: $color--primary;
}
.ant-radio-input:focus + .ant-radio-inner {
   box-shadow: none;
}
.ant-radio-inner {
   // width: 14px;
   // height: 14px;
   border-color: $color--text4;
   &::after {
      // left: calc(50% - 4px);
      // top: calc(50% - 4px);
      background-color: transparent;
      // // bottom: -2px;
      // // right: -2px;
      background-color: $color--primary;
      border: 4px solid $color--primary;
      // // width: auto;
      // // height: auto;
      // // height: 6px;
      // // width: 6px;
      // border-radius: 50%;
   }
}
.ant-radio-checked {
   .ant-radio-inner {
      border-color: $color--primary;
      &::after {
         transition: $transition-common;
      }
   }
   &::after {
      content: none;
   }
}
.ant-radio-disabled + span {
   color: $color--text2;
}
.ant-radio-disabled .ant-radio-inner {
   background-color: transparent;
}
.ant-radio-disabled .ant-radio-inner::after {
   background-color: transparent;
   border-color: $color--text2;
}

/**
* select
**/
.scheme-chart-container {
   .ant-select {
      @include font14;
      color: $color--text2;
   }

   .ant-select-selector {
      box-shadow: none !important;
   }

   .ant-select-arrow {
      color: $color--text2;
      margin-left: 8px;
   }

   .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border: none;
   }

   .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
      border: none;
   }

   .ant-select-selector {
      box-shadow: none;
   }
   .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: 20px;
   }
   .ant-select-single .ant-select-selector .ant-select-selection-item,
   .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
      line-height: 20px;
   }
}
