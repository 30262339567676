.campaign-layout {
   .status {
      color: $color--text3;
      background: $color--bg;
      border: 1px solid rgba(0, 0, 0, 0.04);
      box-sizing: border-box;
      border-radius: 4px;
      padding: 8px 10px;
      width: 110px;
      display: flex;
      justify-content: center;
      align-items: center;
      &.in-progress {
         background: $color--success;
         color: $color--light;
      }
      &.scoring {
         background: $color--info;
         color: $color--light;
      }
   }
   .tab-container {
      margin-bottom: 24px;
   }
   .campaign-name {
      @include font16;
      letter-spacing: 0.005em;
      font-family: $font--semibold;
      color: $color--text1;
   }
}
.campaign-info {
   .name {
      @include font19;
      font-family: $font--semibold;
   }
   .status {
      color: $color--text3;
      background: $color--bg;
      border: 1px solid rgba(0, 0, 0, 0.04);
      box-sizing: border-box;
      border-radius: 4px;
      padding: 4px 8px;
      min-width: 80px;
      width: fit-content;
      margin: 12px 0 0 0;
      &.in-progress {
         background: $color--success;
         color: $color--light;
      }
      &.scoring {
         background: $color--info;
         color: $color--light;
      }
   }
   .action-container {
      margin-top: 12px;
   }
   .action-row {
      padding: 6px 0;
      color: $color--hyper-link;
      cursor: pointer;
      &.lock {
         color: $color--error;
      }
   }
   // .info {
   // margin-bottom: 12px;
   // }
   .team-info {
      padding: 24px 0 12px 0;
      border-bottom: 1px solid $color--gray-border;
      .title {
         @include font16;
         letter-spacing: 0.005em;
         font-family: $font--semibold;
         margin-bottom: 12px;
      }
   }
}
//members
.flexi-modal__body.campaign-members {
   .toggle-switch-row {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      .text {
         margin-left: 10px;
      }
      .toggle-switch {
         display: inline-flex;
      }
   }
   .or {
      margin: 10px 0;
      color: $color--text3;
   }
   .input-file-for-modal.campaign {
      .box-input {
         height: 40px;
      }
   }
   .file-name {
      display: flex;
      align-items: center;
      border: 1px solid #e0e0e0;
      box-sizing: border-box;
      border-radius: 6px;
      padding: 10px 16px;
      .icon-cancel {
         display: inline-flex;
         margin-left: auto;
         cursor: pointer;
      }
   }
}
.action-member-employee-row {
   display: flex;
   align-items: center;
}
.button-recognize {
   padding: 6px 16px;
   border: 1px solid $color--primary;
   box-sizing: border-box;
   border-radius: 4px;
   color: $color--primary;
   margin-right: 24px;
   min-width: 107px;
   text-align: center;
   &:hover {
      background: $color--primary;
      color: $color--light;
      cursor: pointer;
   }
   &.disable {
      background: $color--line;
      color: $color--light;
      border: 1px solid $color--line;
      cursor: not-allowed;
   }
}
.icon-accept {
   color: $color--text3;
   svg {
      cursor: pointer;
   }
   &:hover {
      color: $color--success;
   }
}
.icon-avatar.campaign-members {
   border-radius: 50%;
}

// criteria of a campaign - HR
.campaign-criteria-container {
   .content-loading {
      width: inherit;
   }
   .icon-delete {
      width: fit-content;
      margin-left: auto;
   }
}
.campaign-criteria {
   // margin-bottom: 12px;
   // padding: 12px 32px;
   display: flex;
   justify-content: space-between;
   align-items: center;
   &:hover {
      background: $color--bg;
   }
   .title {
      font-family: $font--semibold;
      @include font16;
      letter-spacing: 0.005em;
      color: $color--text1;
      margin-bottom: 4px;
   }
   .desc {
      @include font14_20;
      color: $color--text3;
      letter-spacing: -0.005em;
   }
   svg {
      color: $color--text3;
   }
}
.modal-add-criteria {
   .campaign-criteria {
      // padding-left: 0;
      margin-bottom: 12px;
      padding: 12px 32px 12px 0;
      &__info {
         margin-left: 8px;
      }
   }
   .lable {
      @include font16;
      color: $color--text3;
      margin-bottom: 24px;
   }
   .create-label {
      color: $color--link-hover;
      cursor: pointer;
   }
   .flexi-modal__body {
      .ant-checkbox-group {
         display: flex;
         flex-direction: column;
         .ant-checkbox-group-item {
            width: inherit;
            &:hover {
               background: $color--bg;
            }
         }
      }
   }
}
// start now modal
.start-now-modal {
   max-width: 350px;
   .flexi-modal__body {
      padding: 8px 32px 24px;
      color: $color--text1;
   }
   &.ant-modal {
      top: 30%;
   }
}
// Finish campaign modal
.finish-campaign-modal {
   .content-box {
      margin-bottom: 24px;
      .title {
         @include font14_20;
         letter-spacing: 0.01em;
         font-family: $font--semibold;
         margin-bottom: 8px;
      }
      .asterisk {
         color: $color--error;
      }
   }
}

//member details
.member-details {
   padding: 16px 32px;
   width: 100%;
   .header {
      display: flex;
      align-items: center;
      .left {
         @include font19;
         font-family: $font--semibold;
         display: flex;
         align-items: center;
      }
      .number {
         background: $color--warning;
         border-radius: 4px;
         color: $color--light;
         @include font14;
         padding: 0 8px;
         margin-left: 8px;
      }
      .right {
         display: flex;
         margin-left: auto;
      }
      .cancel-button {
         padding: 10px 20px;
         color: $color--text1;
         border: 1px solid $color--gray-border;
         border-radius: 6px;
         cursor: pointer;
      }
      .accept-button {
         color: $color--light;
         background: $color--primary;
         border-radius: 6px;
         padding: 10px 20px;
         margin-left: 16px;
         cursor: pointer;
         &.disabled {
            cursor: not-allowed;
            background: $color--bg;
            border: 1px solid rgba(0, 0, 0, 0.1);
            color: $color--text4;
         }
      }
   }
   .recognize-container {
      display: flex;
      justify-content: center;
      .comment-text {
         color: $color--text3;
         font-style: italic;
      }
   }
   .recognize-details {
      width: 666px;
      margin-top: 24px;
   }
   .assessor-layout {
      display: flex;
      .left {
         width: 56px;
         svg {
            width: 48px;
            height: 48px;
         }
      }
      .right {
         width: calc(100% - 56px);
      }
      .right-header {
         display: flex;
      }
      .assessor-name {
         @include font16;
         font-family: $font--semibold;
         margin-bottom: 4px;
      }
      .show {
         @include font12;
         color: $color--text3;
         cursor: pointer;
         transition: $transition-common;
      }
      .actions-button {
         @include common-align-display;
         margin-left: auto;
      }
      .accepted-button {
         min-width: 107px;
         color: $color--success;
         border: 1px solid $color--success;
         box-sizing: border-box;
         border-radius: 4px;
         height: 32px;
         @include common-align-display;
         justify-content: center;
         // margin-left: auto;
         cursor: pointer;
         &.done {
            color: $color--light;
            background: $color--success;
            svg {
               margin-right: 8px;
            }
         }
      }
      .deny-button {
         color: $color--error;
         min-width: 90px;
         display: flex;
         @include common-align-display;
         justify-content: center;
         border-radius: 4px;
         height: 32px;
         margin-right: 12px;
         cursor: pointer;
         &:hover {
            background: $color--text1_04;
         }
      }
      .denied-button {
         color: $color--light;
         background: $color--error;
         min-width: 90px;
         display: flex;
         @include common-align-display;
         justify-content: center;
         border-radius: 4px;
         height: 32px;
         margin-right: 12px;
      }
      .recognize-content {
         border: 1px solid $color--gray-border;
         box-sizing: border-box;
         border-radius: 6px;
         margin-top: 12px;
         padding: 0 20px 16px 20px;
         transition: $transition-common;
      }
      .criteria-title {
         font-family: $font--semibold;
         margin-top: 16px;
      }
      .criteria-value {
         color: #5d5fef;
      }
   }
   .no-recognize {
      padding-top: 32px;
   }
   .avatar {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      object-fit: cover;
   }
}
.member-detail-info {
   &.content-right {
      min-width: 366px;
   }
}

.ant-form-item textarea.ant-input.comment-for-criteria {
   height: 32px;
   transition: $transition-common;
}
.add-comment {
   color: $color--text4;
   cursor: pointer;
   transition: $transition-common;
   .star {
      color: $color--error;
   }
}
.ant-row.checkbox-radio-criteria {
   .input-item .ant-radio-group {
      min-height: 12px;
   }
}
