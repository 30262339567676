.explore-voucher-name {
   display: flex;
}
.voucher-table {
   .benefit-name {
      display: flex;
      align-items: center;
      color: $color--text1;
      .icon-voucher-type {
         margin-left: 8px;
      }
      &.modal-show {
         cursor: pointer;
      }
   }
   .desc {
      @include font12;
      color: $color--text3;
      margin-top: 4px;
   }
   .voucher-status {
      display: flex;
      justify-content: center;
      border-radius: 4px;
      color: $color--light;
      padding: 2px 10px;
      // width: fit-content;
      &.available {
         background: $color--info;
      }
      &.sold_out {
         background: $color--success;
      }
      &.expired {
         background: $color--error;
      }
      &.locked {
         background: $color--text4;
      }
      // exchange voucher status
      &.requested {
         background: $color--info;
      }
      &.received {
         background: $color--success;
      }
      &.reported {
         background: $color--warning;
      }
      &.failed {
         background: $color--error;
      }
      &.succeeded {
         background: $color--success;
      }
   }

   .exchange-button {
      @include font14;
      letter-spacing: -0.005em;
      background: $color--light;
      border: 1px solid $color--hyper-link;
      border-radius: 4px;
      color: $color--hyper-link;
      padding: 5px 15px;
      cursor: pointer;
   }
   .point {
      display: flex;
      align-items: center;
      svg {
         margin-right: 8px;
      }
   }
}

.user-voucher-status {
   display: flex;
   align-items: center;
   justify-content: space-between;
   border-radius: 4px;
   color: $color--light;
   padding: 8px;
   width: 98px;
   height: 32px;
   @include font12;
   &.requested {
      background: $color--warning;
   }
   &.reported {
      background: $color--success;
   }
   &.succeeded,
   &.received {
      background: $color--info;
   }
   &.sent {
      background: $color--info;
      justify-content: center;
   }
   .content {
      display: flex;
   }
}
.status-dropdown {
   &.ant-dropdown.custom-dropdown {
      width: 98px;
      min-width: 98px !important;
      padding: 6px;
      background: $color--light;
      // margin-top: 4px;
      // margin-left: 8px;
   }
}
.status-dropdown-arrow {
   cursor: pointer;
}
.sent-status {
   cursor: pointer;
}

// employee - Exchange Voucher Modal
.exchange-voucher-modal {
   .common-information {
      margin-bottom: 24px;
      padding: 12px 24px;
      background-color: $color--bg;
      border-radius: 6px;
      &__item {
         display: flex;
         @include font14;
         padding: 6px 0;
         &__title {
            color: $color--text3;
            width: 112px;
            margin-right: 12px;
         }
         &__content {
            display: flex;
            color: $color--text1;
            font-family: $font--semibold;
         }
      }
   }
   .icon-voucher-type {
      margin-left: 8px;
   }
   .point {
      color: $color--link-hover;
   }
}

//code
.code-name {
   font-family: $font--semibold;
   @include font16;
}
.status-code {
   width: 80px;
   padding: 4px 0;
   border-radius: 4px;
   color: $color--light;
   display: flex;
   align-items: center;
   justify-content: center;
   &.available {
      background: $color--info;
   }
   &.expired {
      background: $color--error;
   }
   &.sold-old {
      background: $color--success;
   }
   &.locked {
      background: $color--gray-border;
   }
   &.requested {
      background: $color--warning;
   }
}
.voucher-img {
   width: calc(100% + 64px);
   height: 160px;
   margin: -24px -32px 24px -32px;
   object-fit: cover;
}
// Uses code modal - employee
.uses-code-modal {
   .flexi-modal__body {
      margin-bottom: 32px;
      .text {
         @include font16;
         letter-spacing: 0.01em;
         font-family: $font--semibold;
         margin-bottom: 8px;
      }
      .code-box {
         display: flex;
         align-items: center;
         justify-content: space-between;
         border: 1px solid $color--gray-border;
         padding: 8px 16px;
         .copy-btn {
            padding: 6px 12px;
            border-radius: 4px;
            background: $color--text1_04;
            color: $color--hyper-link;
            cursor: pointer;
         }
      }
   }
   .done-btn {
      margin-left: auto;
   }
}
.input-file-container.voucher-code {
   .input-file-for-modal .box-input {
      height: 40px;
   }
   .selected-file {
      padding: 10px 16px;
      border: 1px solid $color--gray-border;
      box-sizing: border-box;
      border-radius: 6px;
      display: flex;
      align-items: center;
   }
   .icon-cancel {
      display: inline-flex;
      margin-left: auto;
      cursor: pointer;
   }
   .input-file-for-modal.upload-code-list.disabled {
      cursor: not-allowed;
      color: $color--text4;
      .button-input {
         color: $color--text4;
      }
      .text-input {
         color: $color--text4;
      }
   }
   .text-input {
      margin-left: 8px;
   }
}

.code-or-file {
   margin-bottom: 12px;
   color: $color--text3;
}
.sample-code-file-row {
   margin: 4px 0 24px 0;
}
.sample-code-file {
   color: $color--primary;
}
