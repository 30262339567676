.user-layout {
   display: flex;
   height: 100vh;
   &.show-right-bar {
      .main-table,
      .main-page {
         width: calc(100% - 365px);
         min-width: 50%;
      }
      .right-bar {
         width: 365px;
         max-width: 50%;
         box-shadow: -1px 0px 0px rgba(0, 0, 0, 0.1);
      }
   }
   &.hide-right-bar {
      .main-table,
      .main-page {
         width: 100%;
      }
      .right-bar {
         display: none;
      }
   }
   .main-table {
      overflow-y: auto;
      .ant-table-content {
         overflow-x: auto;
      }
   }
   .header-container {
      .user-header-right {
         display: flex;
         align-items: center;
      }
      .icon-tab {
         margin-left: 24px;
         cursor: pointer;
      }
   }
   .right-bar {
      overflow-y: auto;
      position: relative;
      &-title {
         @include font24;
         font-family: $font--semibold;
         height: 128px;
         background-color: $color--link-hover;
         padding: 24px 32px;
         color: $color--light;
      }
      .avatar-wrapper {
         width: 96px;
         height: 96px;
         border: 2px solid $color--light;
         border-radius: 50%;
         position: absolute;
         top: 80px;
         left: 32px;
         background-color: $color--light;
         svg {
            width: 100%;
            height: 100%;
         }
         img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
         }
      }
      .avatar-actions {
         height: 48px;
         display: flex;
         align-items: center;
         padding-left: 160px;
         > * {
            margin-right: 29px;
         }
      }
      &-content {
         padding: 24px 32px;
         .detail-item {
            margin-bottom: 16px;
            .label {
               @include font14;
               font-family: $font--regular;
               color: $color--text3;
               margin-bottom: 4px;
               &.edit-profile {
                  font-family: $font--semibold;
                  color: $color--text1;
                  margin-bottom: 8px;
               }
            }
            .value {
               @include font14;
               font-family: $font--regular;
               color: $color--text1;
            }
         }
      }
      .separator-line {
         height: 1px;
         background-color: $color--gray-border;
         margin: 16px 0;
      }
      .actions-row {
         display: flex;
         justify-content: space-between;
         margin-top: 24px;
         .action-button {
            border: 1px solid $color--gray-border;
            border-radius: 6px;
            padding: 10px 20px;
            cursor: pointer;
            &.primary {
               background-color: $color--primary;
               color: $color--light;
               border: none;
            }
            &.disable {
               cursor: not-allowed;
               background-color: $color--gray-border;
            }
         }
      }
   }
}
.user-table-layout {
   .user-row-info {
      @include common-align-display;
      .icon-avatar {
         margin-right: 16px;
         width: 40px;
         height: 40px;
         object-fit: cover;
         border-radius: 50%;
      }
      .name {
         @include font16;
         font-family: $font--semibold;
         color: $color--text1;
         letter-spacing: 0.005em;
         margin-bottom: 4px;
         a {
            color: $color--text1;
         }
      }
      .email {
         color: $color--text2;
         @include font12;
      }
      .not-active {
         color: $color--error;
         margin-top: 4px;
         @include font12;
      }
   }
   .role-row-info {
      @include font14_20;
      .company {
         color: $color--text1;
         text-decoration: underline;
      }
      .role {
         color: $color--text3;
      }
   }
   .button {
      cursor: pointer;
   }
}
