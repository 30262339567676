.add-scheme-modal {
   .row-scheme {
      position: relative;
      margin: 0 !important;
      padding: 16px 24px;
      width: 100%;
      border: 1px solid #e0e0e0;
      border-bottom: 0;
      &:first-child {
         border-radius: 8px 8px 0 0;
      }
   }
   .icon-remove {
      position: absolute;
      right: 0;
      top: 0;
      &:hover {
         color: $color--error;
         cursor: pointer;
      }
   }
   .fy-field {
      margin-bottom: 24px;
   }
   .label {
      font-family: $font--semibold;
      margin-bottom: 8px;
   }
   .add-button {
      width: 100%;
      display: flex;
      align-items: center;
      color: $color--hyper-link;
      padding: 12px 18px;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.005em;
      border: 1px solid #e0e0e0;
      border-radius: 0 0 8px 8px;
      &:hover {
         border: none;
      }
      svg {
         margin-right: 12px;
      }
   }
   .flexi-modal__footer {
      margin-top: 24px;
   }
}
