.flexi-pagination {
   display: flex;
   justify-content: center;
   align-items: center;
   &__item {
      width: 40px;
      height: 40px;
      border-radius: 8px;
      margin: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &.active {
         background-color: $color--button;
         color: $color--light;
      }
      &.left {
         transform: rotate(180deg);
      }
      &.disabled {
         cursor: default;
      }
   }
}
