.flexi-table {
   // box-shadow: $box--shadow-light;
   // border-radius: 8px;
   overflow: hidden;
   .ant-table {
      @include font14_20;
      background: transparent;
   }
   .ant-table-wrapper {
      overflow: hidden;
      .ant-table-thead {
         .ant-table-cell {
            // background: white;
            background: transparent;
            // border-bottom: 1px solid $color--gray-border;
            padding: 12px;
            color: $color--text3;
            &::before {
               width: 0 !important;
            }
            .ant-table-filter-trigger:hover {
               background: white;
            }
            &:first-child {
               padding-left: 32px;
            }
            &:last-child {
               padding-right: 16px;
            }
         }
      }
      .ant-table-thead > tr > th {
         border-bottom: none;
      }

      .ant-table-tbody {
         .ant-table-row {
            position: relative;
            .ant-table-cell {
               border-bottom: none;
               padding: 12px 12px;
               &:first-child {
                  padding-left: 32px;
               }
               &:last-child {
                  padding-right: 16px;
               }
            }
         }
      }
      .ant-spin-nested-loading {
         .ant-spin {
            max-height: none;
         }
      }
      .ant-table-pagination.ant-pagination {
         padding: 8px 12px;
         background: white;
         margin: 0;
         box-shadow: $inner--shadow-line-top;
         .ant-pagination-item-link {
            display: flex;
            align-items: center;
            justify-content: center;
         }
         .ant-pagination-item-link {
            border: none;
            background: $color--bg;
            border-radius: 4px;
         }
         .ant-pagination-item {
            border: 1px solid $color--gray-border;
         }
      }
      .ant-table-column-sorters {
         justify-content: flex-start;
         .ant-table-column-title {
            flex: initial;
            margin-right: 12px;
         }
      }
   }
   .ant-table-row-indent + .ant-table-row-expand-icon {
      background-color: rgba(0, 0, 0, 0);
      border: none;
      &:active {
         outline: none;
      }
      &:hover {
         color: rgba(0, 0, 0, 0);
         border: none;
      }
      margin-right: 14px;
      &::before {
         content: none;
      }
      &::after {
         top: 0px;
         left: 0px;
         width: 16px;
         height: 16px;
         // background: url(../assets/icons/ic_arrow_expand_collapse_right_down.svg);
         background-position: center;
      }
      &-expanded::after {
         transform: rotate(0deg);
      }
      &-collapsed::after {
         transform: rotate(-90deg);
      }
      &-spaced {
         background: $color--gray-border;
         visibility: unset;
         border-radius: 50%;
         max-width: 8px;
         min-width: 8px;
         height: 8px;
         margin-top: 6.5px;
         margin-left: 4px;
         cursor: default;
      }
   }
   .ant-table-tbody > tr > td {
      border-bottom: none;
   }
   .color-icon-search {
      color: $color--gray-border;
      &.active {
         color: $color--error;
      }
   }
   .ant-table-column-sorter-up.active,
   .ant-table-column-sorter-down.active {
      color: $color--error;
   }
   .ant-table-title {
      padding: 16px 16px 4px 24px;
      .header-table {
         display: flex;
         justify-content: space-between;
         &__title {
            @include font20;
            font-family: $font--medium;
         }
         &__link {
            @include font14_24;
            color: $color--text3;
            text-decoration: none;
         }
      }
   }
   .ant-table-tbody > tr.ant-table-row:hover > td {
      background: $color--bg;
      transition: none;
      &.actions-row {
         display: block;
         position: absolute;
         top: 0;
         right: 0;
         background: $color--bg;
         transition: background 0.3s;
         &.department {
            padding: 2px 12px 2px 0;
         }
      }
   }
}
.ant-table-filter-dropdown {
   .search {
      box-shadow: $box--shadow-big;
      border-radius: 12px;
      padding: 16px;
      margin-top: 5px;
      &__input {
         border: 1px solid $color--gray-border;
         box-sizing: border-box;
         border-radius: 6px;
         margin-bottom: 16px;
         max-width: 246px;
         display: block;
      }
      &__reset {
         border: none;
         margin-left: auto;
         color: $color--text3;
         border: none;
      }
      &__done {
         background: $color--primary;
         border-radius: 6px;
         min-height: 32px;
         color: white;
         border: none;
         &:hover {
            background: $color--primary;
         }
      }
   }
   .checkbox-group {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 230px;
      .ant-checkbox-wrapper {
         margin-left: 0;
      }
      .checkbox-wrapper {
         border-radius: 6px;
         padding: 6px;
         margin-bottom: 2px;
         &.checked,
         &:hover {
            background-color: rgba($color: $color--text3, $alpha: 0.05);
         }
         &:last-child {
            margin-bottom: 16px;
         }
      }
      .ant-checkbox-checked .ant-checkbox-inner {
         border-radius: 4px;
         background-color: $color--primary;
         border-color: $color--primary;
      }
   }
}
.user-table {
   .role-cell {
      &.multirow {
         padding-left: 10px;
         position: relative;
         .items::before {
            top: 4px;
            left: 0px;
            content: "";
            width: 2px;
            height: calc(100% - 8px);
            border-radius: 1px;
            background-color: $color--gray-border;
            position: absolute;
            display: block;
         }
      }
   }
   .actions-cell {
      display: flex;
      cursor: pointer;
   }
   .fullname-cell {
      display: flex;
      align-items: center;
      a {
         text-decoration: none;
      }
      svg {
         margin-right: 8px;
         min-width: 16px;
         min-height: 16px;
      }
   }
}

.flexi-pagination {
   background: white;
   padding: 8px 12px;
   box-shadow: $inner--shadow-line-top;
   display: flex;
   .ant-pagination-total-text {
      margin-right: auto;
   }
   .ant-pagination-item-link {
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background: $color--bg;
      border-radius: 4px;
   }
   .ant-pagination-item:hover {
      border-color: $color--primary;
   }
   .ant-pagination-item-active {
      border-color: $color--primary;
   }
   .ant-pagination-jump-next .ant-pagination-jump-prev:focus-visible .ant-pagination-item-link-icon,
   .ant-pagination-jump-next:focus-visible .ant-pagination-item-link-icon,
   .ant-pagination-jump-prev:hover .ant-pagination-item-link-icon,
   .ant-pagination-jump-next:hover {
      .ant-pagination-item-container {
         display: flex;
         height: 32px;
         align-items: center;
      }
      .ant-pagination-item-link-icon {
         color: $color--primary;
      }
   }
}

.actions-row {
   display: none;
   .actions {
      display: flex;
      align-items: center;
      padding: 3px 0 3px 32px;
   }
   .edit {
      color: $color--link-hover;
      padding: 6px 16px;
      border-radius: 16px;
      cursor: pointer;
      border: 1px solid $color--hyper-link;
      &:hover {
         background: $color--light;
      }
   }
   .delete {
      color: $color--error;
      padding: 6px 16px;
      border-radius: 16px;
      border: 1px solid $color--error;
      margin-left: 16px;
      cursor: pointer;
      &:hover {
         background: $color--light;
      }
   }
}

.main-table {
   .action-cell {
      display: flex;
      justify-content: flex-end;
      .action-item {
         margin: 8px;
         cursor: pointer;
         &.disabled {
            pointer-events: none;
            color: $color--text4;
         }
      }
   }
}
