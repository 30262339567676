.add-campaign-modal {
   .tabs {
      display: flex;
      align-items: center;
      margin-bottom: 24px;
      &__item {
         background: $color--text1_04;
         border-radius: 20px;
         margin-right: 8px;
         padding: 6px 12px;
         cursor: pointer;
         &.active {
            background: $color--hyper-link;
            color: $color--light;
         }
      }
   }
   .approver-list {
      margin-top: -20px;
   }
   .members-field-group {
      margin-bottom: 24px;
      .title {
         @include font14;
         font-family: $font--semibold;
         margin-bottom: 4px;
      }
      .upload {
         color: $color--hyper-link;
         cursor: pointer;
      }
   }
   .members-field {
      width: 100%;
   }
}
// campagin detail - member list
.member-recognition {
   color: $color--light;
   font-family: $font--semibold;
   padding: 6px 16px;
   max-width: 80px;
   border-radius: 4px;
   text-align: center;
   &.green {
      background: $color--success;
   }
   &.gray {
      background: $color--text4;
   }
   &.orange {
      background: $color--warning;
   }
}
.member-hr-actions {
   margin: 0 24px 32px;
   background: $color--bg;
   border-radius: 6px;
   padding: 10px 16px;
   display: flex;
   align-items: center;
   justify-content: space-between;
   .left {
      display: flex;
      align-items: center;
      div {
         display: flex;
      }
      svg {
         margin-right: 16px;
         cursor: pointer;
      }
   }
   .right {
      display: flex;
      align-items: center;
      .line {
         background: $color--gray-border;
         width: 1px;
         height: 16px;
         margin: 0 12px;
      }
      .accept {
         color: $color--hyper-link;
         cursor: pointer;
      }
      .lock {
         color: $color--error;
         cursor: pointer;
      }
   }
}
.deny-member-modal {
   .flexi-modal__header {
      .title {
         color: $color--error;
      }
   }
   .deny-submit-btn {
      background: $color--error;
      border-color: $color--error;
      &:hover {
         border-color: $color--error !important;
      }
   }
}
