// benefit
.benefit-layout {
   .status {
      @include font12;
      background: $color--success;
      color: $color--light;
      padding: 4px 10px;
      border-radius: 4px;
      width: fit-content;
   }
}

// package
.package-layout {
   .tab-content {
      margin-top: 16px;
   }
}
// package - company
.comp-actions {
   padding-right: 16px;
   display: flex;
   justify-content: flex-end;
   color: $color--text3;
   .detail {
      color: $color--text3;
      margin-right: 16px;
      &:hover {
         color: $color--link-hover;
      }
   }
   .delete:hover {
      cursor: pointer;
      color: $color--error;
   }
}

// benefit employee
.benefit-name {
   @include font16;
   color: $color--text1;
   letter-spacing: 0.005em;
   font-family: $font--semibold;
   color: $color--text1;
   // cursor: pointer;
}
.benefit-logo {
   margin-right: 16px;
   width: 40px;
   height: 40px;
   object-fit: cover;
}
// summary of benefit employee
.info-header {
   display: flex;
   margin-bottom: 24px;
   .budget {
      margin-left: 32px;
      .value {
         @include font19;
         letter-spacing: 0.005em;
         color: $color--link-hover;
         font-family: $font--semibold;
      }
      .label {
         @include font14_20;
         margin-top: 4px;
         font-family: $font--light;
         color: $color--text2;
         .point {
            color: $color--error;
         }
      }
   }
}
.icon-unlock {
   cursor: pointer;
   &:hover {
      color: $color--warning;
   }
}
// system benefit status
.system-benefit-status {
   display: flex;
   justify-content: center;
   border-radius: 4px;
   color: $color--light;
   padding: 2px 10px;
   @include font14_20;
   &.available {
      background: $color--info;
   }
   &.locked {
      background: $color--warning;
   }
   &.deleted {
      background: $color--text4;
   }
   &.expired, &.not_started {
      background: $color--error;
   }
   &.actived {
      background: $color--info;
   }

}
.benefit-employee-layout {
   .tab-container {
      margin-bottom: 24px;
   }
   .benefit-status {
      display: flex;
      justify-content: center;
      border-radius: 4px;
      color: $color--light;
      padding: 2px 10px;
      // width: 74px;
      // width: fit-content;
      margin-left: 16px;
      @include font14_20;
      &.available {
         background: $color--info;
      }
      &.activated {
         background: $color--success;
      }
      &.expired {
         background: $color--error;
      }
      &.requested {
         background: $color--warning;
      }
      &.denied {
         background: $color--text4;
      }
   }
   // flexible benefit detail
   .header-container {
      width: 100%;
      .status {
         @include font12;
         color: $color--light;
         border-radius: 4px;
         padding: 4px 10px;
         margin-left: 16px;
         &.available {
            background: $color--info;
         }
         &.activated {
            background: $color--success;
         }
      }
   }
   // footer flexible benefit detail
   .footer-content {
      margin-top: 32px;
      padding: 24px 32px;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      .button {
         cursor: pointer;
         margin-top: 24px;
         border-radius: 6px;
         border: none;
         font-family: $font--regular;
         @include font16;
         letter-spacing: 0.005em;
         &.activate {
            padding: 14px 24px;
            color: $color--light;
            background: $color--hyper-link;
         }
         &.disabled {
            background: $color--text4;
            pointer-events: none;
         }
         &.default {
            padding: 12px 24px;
            color: $color--error;
            background: $color--light;
            border: 1px solid rgba(0, 0, 0, 0.1);
         }
      }
   }
   .total-cost {
      text-align: end;
   }
}
// flexible detail table
.available-offer {
   @include font16;
   font-family: $font--semibold;
}
.percentage-containter {
   display: flex;
   justify-content: space-between;
   align-items: center;
   .percentage-coverage {
      display: flex;
      width: 70%;
   }
   .ant-slider {
      width: 80%;
      margin-right: 24px;
   }
}
// activate benefitd modal - flexible detail
.activate-benefitd-modal.warning-modal {
   .content {
      @include font16;
      font-family: $font--light;
   }
   .warning-modal-title {
      color: $color--success;
   }
   .warning-modal-footer .confirm {
      background-color: $color--hyper-link;
   }
   .warning-modal-header {
      margin-bottom: 32px;
   }
   .warning-modal-footer {
      margin-top: 32px;
   }
}
// top-up plan modal - flexible detail
.top-up-modal.warning-modal {
   .content {
      @include font16;
      font-family: $font--light;
      margin: 2px 0;
      .box {
         display: flex;
         background: $color--bg;
         border-radius: 6px;
         padding: 16px 24px;
         margin-top: 12px;
         @include font14_20;
         .box-item {
            // margin-bottom: 12px;
         }
         .value {
            font-family: $font--semibold;
            margin-left: 12px;
            color: $color--hyper-link;
         }
      }
      .box-pay {
         display: flex;
         justify-content: space-between;
         .ant-space {
            grid-gap: 28px !important;
         }
         &__radio {
            padding-top: 6px;
         }
         // .input-select {
         //    .ant-select-selector {
         //       background: $color--light;
         //       border: 1px solid $color--gray-border;
         //       box-sizing: border-box;
         //       border-radius: 6px;
         //    }
         //    &.disabled {
         //       pointer-events: none;
         //    }
         // }
         // .input-item {
         //    max-height: 32px;
         //    .ant-input-number {
         //       min-height: 32px;
         //    }
         //    .number-point {
         //       // margin-top: 16px;
         //       margin-bottom: 16px;
         //       &.disabled {
         //          pointer-events: none;
         //       }
         //    }
         // }
         .number-pay {
            display: flex;
            flex-direction: column;
            .number-point {
               &:first-child {
                  // margin-bottom: 16px;
               }
            }
            .disabled {
               pointer-events: none;
            }
         }
      }
      .title-pay {
         font-family: $font--semibold;
         color: $color--text1;
         margin-top: 16px;
      }
   }
   .warning-modal-footer {
      .cancel {
         color: $color--hyper-link;
         border: 1px solid $color--hyper-link;
         padding: 9px 20px;
      }
      .confirm {
         background-color: $color--hyper-link;
      }
   }
}

// flexible detail activate
.scheme-container {
   border-radius: 6px;
   border-top: 1px solid $color--gray-border;
   border-right: 1px solid $color--gray-border;
   border-left: 1px solid $color--gray-border;
   background: $color--bg;
   margin: 0 32px 24px;
   .scheme-item {
      display: flex;
      justify-content: space-between;
      padding: 12px 24px;
      border-bottom: 1px solid $color--gray-border;
      @include font16;
      &:last-child {
         border-radius: 6px;
      }
      &__name {
         color: $color--text1;
         letter-spacing: 0.005em;
         font-family: $font--semibold;
      }
      &__percent {
         color: $color--text3;
      }
      &__cost {
         margin-left: 16px;
         color: $color--link-hover;
      }
      &.total {
         display: flex;
         justify-content: flex-end;
         .total-value {
            font-family: $font--semibold;
            color: $color--link-hover;
            margin-left: 16px;
         }
      }
   }
}

// packages table of available flexible benefit
.flexi-package-table {
   .ant-table-summary {
      padding: 12px;
      .packages-cost-total {
         display: flex;
         width: inherit;
         @include font16;
         .label {
            width: max-content;
            color: $color--text1;
            font-family: $font--light;
         }
         .value {
            font-family: $font--semibold;
            color: $color--hyper-link;
            margin-left: 16px;
         }
      }
      .ant-table-cell {
         border-bottom: none;
      }
      .cell-total {
         text-align: end;
         .total-cost {
            color: $color--link-hover;
            font-family: $font--semibold;
         }
      }
   }
}
.no-package {
   padding: 32px;
   color: $color--text3;
}

// top-up table
.top-up-table {
   .benefit-name {
      display: flex;
      align-items: center;
      color: $color--text1;
      .status {
         font-family: $font--regular;
         margin-top: 4px;
         @include font12;
         // status of top-up plan of role employee
         &.approved {
            color: $color--success;
         }
         &.denied {
            color: $color--error;
         }
         &.requested {
            color: $color--hyper-link;
         }
         // status of top-up plan of role HR
         &.hr {
            &.approved {
               color: $color--hyper-link;
            }
            &.denied {
               color: $color--error;
            }
            &.requested {
               color: $color--success;
            }
         }
      }
   }
}

.hidden-footer-modal {
   display: none;
}
