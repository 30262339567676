.benefit-manager-container {
   .tab-container {
      margin-bottom: 24px;
   }
   .no-schemes {
      margin-top: 24px;
      text-align: center;
   }
}

.requests-table {
   .status-badge {
      color: $color--light;
      padding: 4px 8px;
      border-radius: 4px;
      text-align: center;
      &.NEW {
         background-color: #0042ed;
      }
      &.DENIED {
         background-color: #fa4d56;
      }
      &.APPROVED {
         background-color: #ff8946;
      }
      &.COMPLETED {
         background-color: #42be65;
      }
   }
}

.plan-table {
   .status-badge {
      color: $color--light;
      padding: 4px 8px;
      border-radius: 4px;
      text-align: center;
      &.DENIED {
         background-color: #fa4d56;
      }
      &.APPROVED {
         background-color: #42be65;
      }
      &.REQUESTED {
         background-color: #0042ed;
      }
   }
}

.invoice-table {
   .invoice-name {
      cursor: pointer;
   }
}
.invoice-detail-modal,
.hr-topup-plan-modal {
   .flexi-modal__body {
      padding-bottom: 32px;
   }
   .common-information {
      padding: 12px 24px;
      background-color: $color--bg;
      border-radius: 6px;
      &__item {
         display: flex;
         @include font14;
         padding: 6px 0;
         &__title {
            color: $color--text3;
            width: 112px;
            margin-right: 12px;
         }
         &__content {
            color: $color--text1;
            font-family: $font--semibold;
         }
      }
      .sub-text {
         color: $color--text3;
         font-family: $font--regular;
         margin-left: 8px;
      }
   }
   .attachment-title {
      font-family: $font--semibold;
      margin-bottom: 8px;
      margin-top: 24px;
   }
   .attachment-list {
      border-radius: 6px;
      border: 1px solid $color--gray-border;
   }
   .attachment-item {
      padding: 10px 16px;
      border-bottom: 1px solid $color--gray-border;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:last-child {
         border-bottom: none;
      }
      &__name {
         cursor: pointer;
      }
      svg {
         cursor: pointer;
      }
   }
   .ant-checkbox-wrapper {
      display: flex;
      margin-bottom: 12px;
      margin-left: 0px;
      cursor: default;
      * {
         cursor: default;
         color: $color--text1;
      }
      input {
         border: none;
      }
   }
   .flexi-modal__footer {
      padding: 0px;
      .btn-deny {
         margin-right: 12px;
         background-color: #FA4D56;
         border-color: #FA4D56!important;
      }
      .btn-approve {
         background-color: #42BE65;
         border-color: #42BE65!important;
      }
   }
}

.description-benefit {
   max-width: 300px;
   white-space: nowrap;
   text-overflow: ellipsis;
   overflow: hidden;
   color: $color--text2;
   font-family: $font--regular;
   @include font12;
}
