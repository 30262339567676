// @font-face {
//    font-family: "BioSans-Bold";
//    font-style: normal;
//    src: local("BioSans Bold"), local("BioSans-Bold"), url("../assets/fonts/be/BioSans-Bold.otf") format("truetype");
//    font-display: swap;
// }

// @font-face {
//    font-family: "BioSans-Regular";
//    font-style: normal;
//    src: local("BioSans Regularr"), local("BioSans-Regular"),
//       url("../assets/fonts/be/BioSans-Regular.otf") format("truetype");
//    font-display: swap;
// }

// @font-face {
//    font-family: "BioSans-SemiBold";
//    font-style: normal;
//    src: local("BioSans SemiBold"), local("BioSans-SemiBold"),
//       url("../assets/fonts/be/BioSans-SemiBold.otf") format("truetype");
//    font-display: swap;
// }

// @font-face {
//    font-family: "BioSans-Light";
//    font-style: normal;
//    src: local("BioSans Light"), local("BioSans-Light"), url("../assets/fonts/be/BioSans-Light.otf") format("truetype");
//    font-display: swap;
// }

@font-face {
   font-family: "BioSans-Bold";
   font-style: normal;
   src: local("BioSans Bold"), local("BioSans-Bold"), url("../assets/fonts/be/BioSans-Bold.ttf") format("truetype");
   font-display: swap;
}

@font-face {
   font-family: "BioSans-Regular";
   font-style: normal;
   src: local("BioSans Regularr"), local("BioSans-Regular"),
      url("../assets/fonts/be/BioSans-Regular.ttf") format("truetype");
   font-display: swap;
}

@font-face {
   font-family: "BioSans-SemiBold";
   font-style: normal;
   src: local("BioSans SemiBold"), local("BioSans-SemiBold"),
      url("../assets/fonts/be/BioSans-SemiBold.ttf") format("truetype");
   font-display: swap;
}

@font-face {
   font-family: "BioSans-Light";
   font-style: normal;
   src: local("BioSans Light"), local("BioSans-Light"), url("../assets/fonts/be/BioSans-Light.ttf") format("truetype");
   font-display: swap;
}
