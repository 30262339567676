.main-layout {
   display: flex;

   &.sidebar-open .common-layout {
      // width: calc(100% - 334px);
      width: calc(100vw - 334px);
   }
   &.sidebar-closed .common-layout {
      width: calc(100% - 32px);
   }

   .leftmenu-container {
      // min-width: 334px;
      width: 334px;
      height: 100vh;
      display: flex;
      flex-direction: column;
      position: sticky;
      background: $color--bg;
      padding: 0 16px;
      top: 0;
      transition: all 0.3s ease;
      overflow: scroll;
      border-right: 1px solid $color--gray-border;
      &::-webkit-scrollbar {
         width: 0; /* Remove scrollbar space */
         background: transparent; /* Optional: just make scrollbar invisible */
      }
      // /* Optional: show position indicator in red */
      // &::-webkit-scrollbar-thumb {
      //    background: #ff0000;
      // }

      .separator-line {
         height: 1px;
         background-color: $color--gray-border;
         margin: 24px 0;
      }

      .management-title {
         padding: 12px 16px;
      }

      .show-hide {
         // position: absolute;
         // right: -18px;
         position: fixed;
         left: 316px;
         top: 28px;
         border-radius: 50%;
         display: none;
         cursor: pointer;
         z-index: 999;
      }

      &:hover {
         .show-hide {
            display: block;
         }
      }

      .main-logo {
         padding: 24px 20px;
      }

      .tab-content {
         transition: all 0.3s ease;
         .tab-item {
            color: $color--text2;
            padding: 12px 16px;
            display: flex;
            align-items: center;
            transition: all 0.3s ease;
            @include font16;

            .icon {
               color: $color--text4;
               margin-right: 16px;
               display: inline-flex;
               transition: all 0.3s ease;
            }
            &:hover {
               transition: all 0.3s ease;
               background: rgba(22, 22, 22, 0.05);
               border-radius: 6px;
            }
         }
         &.active {
            .tab-item,
            .icon {
               transition: all 0.3s ease;
               color: $color--primary;
            }
         }
      }

      .profile-container {
         margin: auto -4px 0 -4px;
         padding: 20px 0;
      }
      .profile {
         padding: 12px;
         display: flex;
         align-items: center;

         &:hover {
            background: rgba(22, 22, 22, 0.05);
            border-radius: 6px;
         }
         svg {
            width: 40px;
            height: 40px;
         }
         img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-right: 16px;
            object-fit: cover;
         }
         .icon-avatar {
            margin-right: 16px;
         }
         .name {
            @include font16_24;
            font-family: $font--semibold;
            max-width: 180px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
         }
         .des {
            color: $color--text2;
            @include font12;
            font-family: $font--light;
         }
         .icon-more {
            color: $color--text4;
            margin-left: auto;
            cursor: pointer;
            svg {
               width: 24px;
               height: 24px;
            }
         }
      }
   }
   .leftmenu-small {
      width: 32px;
      height: 100vh;
      background: $color--bg;
      position: relative;
      transition: all 0.3s ease;
      .show-hide {
         position: absolute;
         right: -18px;
         top: 28px;
         border-radius: 50%;
         cursor: pointer;
         z-index: 2;
      }
   }
}
.ant-dropdown {
   &.custom-dropdown.dark.profile {
      .ant-dropdown-menu-title-content {
         font-family: $font--semibold;
      }
      .ant-dropdown-menu-item:hover,
      .ant-dropdown-menu-submenu-title:hover {
         margin: 0 8px;
         padding: 10px 12px;
         border-radius: 4px;
      }
      .setting-item {
         display: flex;
         align-items: center;
         color: $color--light;
         svg {
            margin-right: 12px;
         }
         &.logout {
            color: $color--error;
         }
      }
   }
}
.language-menu {
   color: $color--light;
   padding: 12px 12px;
   margin: 0 8px;
   font-family: $font--semibold;
   @include common-display;
   .icon-language {
      margin-right: 12px;
   }
   .icon-arrow-16 {
      margin-right: -4px;
      color: $color--text4;
   }
   &:hover {
      cursor: pointer;
      border-radius: 4px;
      transition: all 0.3s;
      clear: both;
      background: rgba(255, 255, 255, 0.1);
   }
}
.dropdown-language {
   width: 180px;
   position: absolute;
   left: 240px;
   top: -52px;
   color: $color--light;

   background: $color--text1;
   box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.4);
   border-radius: 6px;
   padding: 8px 0;
   &__option {
      @include common-display;
      margin: 0 8px 0 8px;
      padding: 10px 8px;
      .icon-check {
         color: $color--success;
      }
      &:hover {
         cursor: pointer;
         border-radius: 4px;
         background: rgba(255, 255, 255, 0.1);
      }
   }
}
