.insight-container {
   padding: 0 32px;
   .info-row {
      display: flex;
      &--item {
         // flex: 1;
         margin-right: 32px;
         .title {
            @include font14;
            color: $color--text2;
            margin-bottom: 4px;
         }
         .value {
            @include font19;
            color: $color--primary;
         }
      }
   }
   .content-right {
      width: 100%;
      // height: 100vh;
      position: sticky;
      top: 0;
      padding: 0 32px 24px 32px;
      // overflow: scroll;
      transition: $transition-common;
      align-self: flex-start;

      &.right-bar-hide {
         transition: $transition-common;
         width: 0;
         padding: 0;
      }

      .header {
         display: flex;
         align-items: center;

         img {
            margin-right: 16px;
            object-fit: cover;
         }
      }

      .company-name {
         font-family: $font--semibold;
         @include font16_24;
         white-space: nowrap;
         overflow: hidden;
         text-overflow: ellipsis;
         width: 250px;
      }

      .upload-image {
         color: $color--primary;
      }

      .logo-company {
         width: 48px;
         height: 48px;
      }

      .info {
         padding: 30px 0 10px 0;
         border-bottom: 1px solid $color--gray-border;
      }

      .representative {
         padding: 22px 0 10px 0;
         // border-bottom: 1px solid $color--gray-border;
      }

      .row-item {
         display: flex;
         width: 100%;

         .label {
            width: 120px;
            color: $color--text3;
            padding-right: 12px;
            margin-right: auto;
            margin-bottom: 12px;
         }

         .text {
            width: calc(100% - 120px);
         }
      }

      .edit {
         color: $color--primary;
         cursor: pointer;
         margin-top: 16px;
      }
   }
}
.scheme-chart-container {
   border: 1px solid #e0e0e0;
   border-radius: 6px;
   margin-top: 24px;
   .sumary {
      border-top: 1px solid #e0e0e0;
   }
   .border-right {
      border-right: 1px solid #e0e0e0;
   }
   .col-item {
      padding: 20px 24px;
      .title {
         margin-bottom: 8px;
         color: $color--text3;
      }
      .name {
         @include font16;
         font-family: $font--semibold;
         margin-bottom: 8px;
         &.most {
            color: $color--primary;
         }
         &.least {
            color: $color--warning;
         }
      }
      .percent {
         @include font16;
      }
   }
   .header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
      padding: 20px 24px;

      .title {
         @include font19;
         font-family: $font--semibold;
      }
   }
   .chart {
      // height: 500px;
      margin-bottom: 24px;
   }
   .barchart {
      height: 256px;
      width: 256px;
   }
   .barchart-container {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      .title {
         @include font19;
         font-family: $font--semibold;
         text-align: center;
         margin-top: 20px;
      }
   }
   &.point-and-budget {
      margin-bottom: 48px;
   }

   .custom-tooltip {
      background: #ffffff;
      border: 1px solid #e0e0e0;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
      border-radius: 4px;
      width: 200px;
      .label {
         font-family: $font--semibold;
         padding: 12px 16px;
         border-bottom: 1px solid #e0e0e0;
      }
      .in-using {
         padding: 12px 16px;
      }
      .title {
         @include font12;
         color: $color--text2;
      }
      .value {
         font-family: $font--semibold;
      }
   }
}
.report-employee {
   .budget-chart {
      border: 1px solid #e0e0e0;
      border-radius: 6px;
      margin-top: 24px;
      .content-chart {
         display: flex;
         flex-direction: column;
         align-items: center;
      }
      .chart-name {
         font-size: 19px;
         line-height: 24px;
         font-family: $font--semibold;
         padding-top: 24px;
         text-align: center;
      }
      .col-item {
         padding: 20px 24px;
         border-top: 1px solid #e0e0e0;
         &.first {
            border-right: 1px solid #e0e0e0;
         }
         .title {
            margin-bottom: 8px;
            color: $color--text3;
         }
         .name {
            @include font16;
            font-family: $font--semibold;
            margin-bottom: 8px;
            &.most {
               color: $color--primary;
            }
            &.least {
               color: $color--warning;
            }
         }
         .percent {
            @include font16;
         }
      }
   }
}
.line-chart {
   height: 288px;
   width: 302px;
   margin-top: 24px;
}
.selected-row {
   display: flex;
   .ant-picker {
      border-radius: 6px 0 0 6px;
      background: rgba(0, 0, 0, 0.06);
      border: none;
   }
   .button-add.report {
      border-radius: 0 6px 6px 0;
      padding-left: 0;
   }
   .ant-picker-focused {
      box-shadow: none;
   }
}
.button-add.report {
   background: rgba(0, 0, 0, 0.06);
   span {
      color: $color--text3;
   }
   .line {
      margin: 0 12px 0 4px;
   }
   .type-select {
      color: $color--text1;
   }
}
