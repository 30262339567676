.flexi-modal {
   max-height: 568px;

   .modal-body {
      padding: 24px;
      .ant-form-item {
         margin-bottom: 8px;
      }
      .ant-form {
         &-item-label {
            width: 170px;
            display: flex;
            > label {
               @include font14_24;
               font-family: $font--medium;
               &.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
                  position: absolute;
                  left: 100%;
               }
               &::after {
                  content: none;
               }
            }
         }
         .switch-button {
            background-color: $color--bg;
            border-radius: 8px;
            color: $color--primary;
            padding: 8px 12px;
            width: max-content;
            cursor: pointer;
            display: flex;
            align-items: center;
            margin-left: 170px;
            .icon-upload {
               // background: url("../assets/icons/ic_upload_purple.svg");
               margin-right: 12px;
               width: 16px;
               height: 16px;
            }
            .icon-cancel {
               // background: url("../assets/icons/ic_add_cancel_purple.svg");
               margin-right: 12px;
               width: 16px;
               height: 16px;
            }
            &:hover {
               background-color: $color--primary;
               color: white;
               .icon-upload {
                  // background: url("../assets/icons/ic_upload_white.svg");
               }
               .icon-cancel {
                  // background: url("../assets/icons/ic_add_cancel_white.svg");
               }
            }
         }
         .switch-to-file {
            position: absolute;
         }
      }
   }
   &__header {
      padding: 32px 32px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title {
         @include font24;
         color: $color--link-hover;
         font-family: $font--semibold;
      }
   }
   &__body {
      padding: 24px 32px 0;
      .ant-form-item {
      }
   }
   &__footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 32px 32px;
      .ant-form-item {
         margin-bottom: 0;
      }
   }
}
.invite-modal {
   .modal-body {
      padding: 24px;
      .title {
         @include font20;
         letter-spacing: 0.02em;
         display: flex;
         margin-bottom: 24px;
         align-items: center;
         .icon-wrapper {
            width: 40px;
            height: 40px;
            border-radius: 20px;
            background-color: $color--primary;
            margin-right: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
         }
      }
      .input-item {
         margin-bottom: 24px;
      }
      .button-row {
         display: flex;
         justify-content: space-between;
         align-items: center;
         .button-wrapper {
            display: flex;
            .cancel-button,
            .confirm-button {
               @include font14_24;
               padding: 8px 16px;
               border-radius: 8px;
            }
            .cancel-button {
               background-color: $color--bg;
            }
            .confirm-button {
               background-color: $color--success;
               margin-left: 12px;
               color: white;
               &:hover {
                  background-color: $color--success-bg;
               }
            }
         }
      }
   }
}

.warning-modal {
   .ant-modal-body {
      padding: 32px;
   }
   &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;
   }
   &-title {
      color: $color--error;
      @include font24;
      font-family: $font--semibold;
   }
   .text-semiBold {
      font-family: $font--semibold;
   }
   .ant-modal-close {
      top: 32px;
      right: 32px;
   }
   &-footer {
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &-button {
         padding: 10px 20px;
         border-radius: 6px;
         @include font14;
         cursor: pointer;
      }
      .cancel {
         color: $color--text1;
         border: 1px solid #e0e0e0;
      }
      .confirm {
         color: $color--light;
         background-color: $color--error;
      }
   }
}
