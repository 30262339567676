.employee-layout {
   .main-table {
      .title-group .number {
         margin-left: 0px;
      }
      .enroll-status {
         @include font12;
         padding: 4px 0;
         text-align: center;
         width: 84px;
         border-radius: 4px;
         color: $color--light;
         &.enrolled {
            background-color: $color--success;
         }
         &.not-enrolled {
            background-color: $color--error;
         }
         &.not-activated {
            background-color: $color--text4;
         }
         &.locked {
            background-color: $color--warning;
         }
      }
   }
}

.multi-employee-action {
   display: flex;
   align-items: center;
   justify-content: space-between;
   height: 40px;
   background-color: $color--bg;
   border-radius: 6px;
   margin: 1px 32px 25px 32px;
   .left {
      display: flex;
      align-items: center;
      padding: 0 18px;
      height: 100%;
      .icon-x {
         cursor: pointer;
         margin-right: 16px;
         height: 16px;
      }
   }
   .right {
      display: flex;
      align-items: center;
      .action-text {
         padding: 10px 12px;
         margin-bottom: 0px;
      }
      .vertical-separator {
         background-color: #e0e0e0;
         height: 16px;
         width: 1px;
      }
   }
}

.employee-detail {
   .header {
      font-family: $font--semibold;
      @include font24;
      color: $color--text1;
   }
   .summary {
      padding: 0 32px;
      .value {
         @include font19;
         font-family: $font--semibold;
         color: #0042ed;
      }
      .text {
         color: $color--text2;
      }
   }
   .remind-button {
      display: flex;
      align-items: center;
      background-color: #fa4d56;
      color: $color--light;
      border: none !important;
      margin-left: 32px;
      svg {
         margin-right: 10px;
      }
   }
   .not-enrolled {
      margin: 32px 32px 16px 32px;
      color: $color--text3;
   }
   .summary-item {
      margin-bottom: 12px;
   }
   .action-text {
      &.disabled {
         pointer-events: none;
         color: $color--text4;
      }
   }
}
.add-point-modal {
   .user-information {
      padding: 16px 24px;
      background-color: $color--bg;
      border: 1px solid $color--gray-border;
      border-radius: 6px;
      margin-bottom: 24px;
      .label {
         color: $color--text3;
         padding: 6px 0;
      }
      .value {
         color: $color--text1;
         font-family: $font--semibold;
         padding: 6px 0;
      }
   }
}
.common-layout.employee-details {
   width: 100% !important;
}
